import { Grid, IconButton, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import axios from "../../api/axios"
import useAccessTokenInfo from "../../hooks/useAccessTokenInfo"
import { GET_ABOUT_ME_URL, GET_PARTNER_PREF_URL, GET_PERSONAL_URL, GET_SPIRITUAL_URL } from "../../pages/URLs"
import SectionDecoder from "./SectionDecoder"
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import StructureContextUpdate from "../../StructureDecoder/context/StructureProviderUpdate"
import StructureContext from "../../StructureDecoder/context/StructureProvider"
import LoadingComp from "../IndividualComponents/LoadingComp"
import { mainColors } from "../Theme/Colors/palette"
import { purple } from "@mui/material/colors"

const apiArray = [GET_PERSONAL_URL, GET_ABOUT_ME_URL, GET_SPIRITUAL_URL, GET_PARTNER_PREF_URL]
const apiKeys = [process.env.REACT_APP_GET_PERSONAL_KEY, process.env.REACT_APP_GET_ABOUT_ME_KEY, process.env.REACT_APP_GET_SPIRITUAL_KEY, process.env.REACT_APP_GET_PARTNER_PREFS_KEY]

const PageDecoder = (props) => {

    const path = props.path

    const info = useAccessTokenInfo()
    const user = info?.user || ""

    const { page, setPage } = useContext(StructureContext)
    const { dontSave, edit, setEdit, setCopyPage, save, loading } = useContext(StructureContextUpdate)

    useEffect(() => {
        const thisPage = page(path[0])

        const updateValsStructure = (data) => {
            if (!data) {
                return
            }
            const keys = Object.keys(data)
            keys.forEach(key => {
                const fields = Object.keys(data[key])
                fields.forEach(field => {
                    if (key === "Personal" && field === "age") {
                        return
                    }
                    if (thisPage[key][field]) {
                        thisPage[key][field].value = data[key][field]
                    }
                })
            })
        }

        const getAboutMeData = async (username) => {
            if (username) {
                try {
                    await axios.post(apiArray[path[0]-1], 
                        JSON.stringify({ user: username }),
                        {
                            headers: { 'Content-Type': 'application/json',
                            'x-functions-key': `${apiKeys[path[0]-1]}` },
                            withCredentials: true
                        }
                    ).then(response => {
                        if (response?.data) {
                            updateValsStructure(response.data)
                            setPage(path[0], thisPage)
                            setCopyPage(path[0], structuredClone(thisPage))
                        }
                    })
                } catch (err) {
                    console.log(err)
                }
            }
        }
        getAboutMeData(user)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const pageStructure = page(path[0])
    const titles = Object.keys(pageStructure)

    return (
        <Grid container
        sx={{mt:12}}>
            {titles.map((title) => {
                var pathPerTitle = [...path]
                pathPerTitle.push(title)
                return (
                    <Grid key={title} item xs={12}
                    style={{
                        pointerEvents: loading ? "none" : ""
                    }}
                    >
                        <Grid container>

                            <Grid item xs={12}
                            display="flex"
                            justifyContent="left"
                            sx={{px:2}}>
                                <Grid container
                                display="flex"
                                alignItems="center"
                                >
                                    <Grid item>
                                        <Typography variant="h3"
                                        display="flex"
                                        alignItems="flex-end"
                                        sx={{mr:"1.5vw", ml:2, mb:2}}
                                        marginBottom="0">
                                            {title}
                                        </Typography >
                                    </Grid>
                                    <Grid item
                                    sx={{mb:2}}
                                    >
                                        {edit(path[0])
                                        ? (
                                            <>
                                                {
                                                    loading ?
                                                    <LoadingComp width={"7vh"}/> :
                                                    (
                                                    <>
                                                    <IconButton
                                                    onClick={() => {
                                                        save(path[0])
                                                    }}
                                                    >
                                                        <SaveIcon style={{ fontSize: "3.5vh", fill: mainColors.green }}/>
                                                    </IconButton>
                                                    <IconButton
                                                    onClick={() => {
                                                        setEdit(path[0], false)
                                                        dontSave(path[0])
                                                    }}
                                                    >
                                                        <CloseIcon style={{ fontSize: "3.5vh", fill: mainColors.important }}/>
                                                    </IconButton>
                                                    </>
                                                    )
                                                }
                                            </>
                                        )
                                        : (
                                        <IconButton
                                        onClick={() => setEdit(path[0], true)}
                                        >
                                            <EditIcon style={{ fontSize: "3.5vh", fill: purple[500] }}/>
                                        </IconButton>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}
                            style={{
                                pointerEvents: edit(path[0]) ? "" : "none"
                            }}
                            >
                                <SectionDecoder 
                                path={pathPerTitle}
                                />
                            </Grid>

                        </Grid>
                    </Grid>

                )
            })}
        </Grid>
    )
}

export default PageDecoder