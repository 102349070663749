import { Button, Grid, Typography, useMediaQuery } from "@mui/material"
import { delay, motion } from "framer-motion"
import { useEffect, useState } from "react"
import HeroAnimation from "../components/CombinedComponents/HeroAnimation"
import HeroInterest from "../components/CombinedComponents/HeroInterest"
import GlintWipe from "../components/IndividualComponents/GlintWipe"
import Sparklez from "../components/IndividualComponents/Sparklez"

const Hero = () => {

    // start sequence
    const startDelay = 3500
    const mergeAnimationLength = 2000
    const strikeThroughLineDuration = 1000
    const strikeThroughOffset = 600
    const textAnimationDuration = 1500
    const textOffset = 600
    const diamondOffset = 600
    const glintDelay = 600
    const glintDuration = 1200

    //exit animations
    const textFadeDuration = 600
    const lineClosingDuration = 1400
    const scaleDownDuration = 2000
    const buttonFadeOut = 1000
    const finalShine = 900

    const endOfStartAnime = startDelay/1000 + mergeAnimationLength/1000 + strikeThroughLineDuration/1000 - strikeThroughOffset/1000 + textAnimationDuration/1000 - textOffset/1000 -diamondOffset/1000 + glintDelay/1000
    const scaleDownDelay = lineClosingDuration/1000

    const isLaptop = useMediaQuery('(min-width:1280px)');
    const isTablet = useMediaQuery('(min-width:601px)');

    const resFontSize = (laptop, tablet, mobile) => {
        return isLaptop ? laptop
               : isTablet ? tablet
               : mobile
    }

    const [enter, setEnter] = useState(false);
    const [delayEnter, setDelayEnter] = useState(false);
    const [doneAnime, setDoneAnime] = useState(false)
    const [switchEnd, setSwitchEnd] = useState(false)
    const [buttonAccess, setButtonAccess] = useState(false)

    setTimeout(() => {
        setButtonAccess(true)
    }, endOfStartAnime * 1000 + 2000)

    useEffect(() => {
        if (enter) {
            setTimeout(() => {
                setDoneAnime(true)
            }, lineClosingDuration+scaleDownDuration+buttonFadeOut)
        }
    }, [enter]);

    useEffect(() => {
        if (doneAnime) {
            setTimeout(() => {
                setSwitchEnd(true)
            }, 3000)
        }
    }, [doneAnime])

    const buttonVariants = {
        startState: {
            opacity: 0
        },
        fadein: {
            opacity: 1,
            transition: {
                delay: endOfStartAnime + 2,
                duration: 4,
                ease: "linear"
            }
        },
        fadeout: {
            opacity: 0,
            transition: {
                duration: buttonFadeOut/1000
            }
        }
    }

    if (switchEnd) {
        return (
            <HeroInterest/>
        )
    }

    return (
        <>
        <Grid container
        style={{ background: "#000", height: "100vh", width: "100vw", maxWidth: "100%" }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        >
            <Grid container
            style={{ width: "100vw", height: "100vh", maxWidth: "100%" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            >
                {
                    doneAnime 
                    ? (
                        <GlintWipe
                        finalShine={finalShine}
                        />
                    )
                    : (
                        <>
                            <div
                            style={{ zIndex: 1, position: "absolute" }}
                            >
                                <Sparklez
                                endOfStartAnime={endOfStartAnime}
                                scaleDownDelay={scaleDownDelay}
                                enter={delayEnter}
                                scaleDownDuration={scaleDownDuration}
                                />
                            </div>
                            <div
                            style={{ zIndex: 2, position: "absolute" }}
                            height="100vh"
                            >
                                <HeroAnimation height={"80vh"} 
                                enter={delayEnter}
                                startDelay={startDelay}
                                mergeAnimationLength={mergeAnimationLength}
                                strikeThroughLineDuration={strikeThroughLineDuration}
                                strikeThroughOffset={strikeThroughOffset}
                                textAnimationDuration={textAnimationDuration}
                                textOffset={textOffset}
                                diamondOffset={diamondOffset}
                                glintDelay={glintDelay}
                                glintDuration={glintDuration}
                                textFadeDuration={textFadeDuration}
                                lineClosingDuration={lineClosingDuration}
                                scaleDownDuration={scaleDownDuration}
                                />
                            </div>
                        </>
                    )
                }
            </Grid>
            {
                !delayEnter 
                ? (
                    <motion.div
                    style={{ zIndex: 21, position: "absolute", bottom: "1vh" }}
                    variants={buttonVariants}
                    initial="startState"
                    animate={enter ? "fadeout" : "fadein"}
                    >
                        <Button
                        variant="backCP"
                        sx={{ mb: "4vh", color: "#fff" }}
                        disableRipple
                        onClick={() => {
                            setEnter(true)
                            setTimeout(() => {
                                setDelayEnter(true)
                            }, buttonFadeOut+1)
                        }}
                        disabled={!buttonAccess}
                        >
                            <Typography
                            style={{
                                fontFamily: "Quicksand",
                                fill: "#fff",
                                fontSize: resFontSize("32px", "25px", "18px")
                            }}
                            >
                                ENTER
                            </Typography>
                        </Button>
                    </motion.div>
                )
                : (
                    <></>
                )
            }
            
        </Grid>
        </>
    )
}

export default Hero