import TitleTextPageLayout from "../components/CombinedComponents/TitleTextPageLayout";

const introText = {
    title: "Data Protection Policy",
    introduction: `Effective Date: 21-08-2023

    Welcome to our Krishna Marriage community! We are committed to protecting your personal information and ensuring your data is handled responsibly. This Data Protection Policy outlines how we collect, process, store, and secure your data when you use our Krishna Marriage website (the "Website"). By using the Website, you consent to the practices described in this Data Protection Policy.
    
    Data We Collect

    We collect personal information you provide when you create an account, update your profile, or communicate with other members.
    
    We collect usage data, including your interactions with the Website, matches, and browsing activities.`
}

const policy = [
    {
        title: "How we use your data",
        text: `We use your data to create and manage your account, facilitate connections and matches, personalise your experience, and communicate with you.
        
        We may use aggregated and anonymized data for research, analysis, and improving our services.`
    },
    {
        title: "Data Sharing",
        text: `We may share your data with other members to facilitate connections and matches.

        We may share data with third-party service providers for purposes such as data analysis, customer support, and marketing.`
    },
    {
        title: "Data Security",
        text: `We implement technical and organizational measures to protect your data, including encryption and secure data storage.
        
        While we take reasonable steps to safeguard your data, no method of transmission over the internet is completely secure.`
    },
    {
        title: "Data Retention",
        text: "We retain your data as long as necessary for the purposes outlined in this policy or as required by law."
    },
    {
        title: "Your Rights",
        text: `You have the right to access, rectify, or delete your data, and to restrict or object to its processing.
        
        You can exercise your rights by contacting us at admin@krishnamarriage.com`
    },
    {
        title: "Changes to This Policy",
        text: "We may update this Data Protection Policy from time to time. Any changes will be posted on this page."
    },
    {
        title: "Contact Us",
        text: "If you have questions or concerns about our Data Protection Policy, please contact us at admin@krishnamarriage.com."
    }
]

const footerText = {
    title: "Thank you for reading",
    conclusion: "By using the Website, you agree to the terms of this Data Protection Policy. If you do not agree with our practices, please do not use the Website. Thank you for being a part of our Krishna Marriage community!"
}

const DataProtection = () => {
    return (
        <TitleTextPageLayout titleTextArray={policy} headerTextObj={introText} footerTextObj={footerText}/>
    )
}

export default DataProtection
