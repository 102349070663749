import { Grid } from "@mui/material"
import Untitled from "../../img/Untitled.png"
import { useContext } from "react";
import ResponsiveContext from "../../ResponsiveDesign/ResponsiveProvider";
import KMButton from "../IndividualComponents/KMButton";
import { useLocation, useNavigate } from "react-router-dom";
import { landingPage, loginPage, registerPage } from "../../pages/URLs";

const NonAuthNavBar = ({ links }) => {

    const location = useLocation().pathname
    const navigate = useNavigate()

    const navBarStyles = {
        "default": {
            opacity: 1,
            endButtons: (
                <Grid item>
                    <KMButton text={"Login"} bold upperCase
                    variant={"nonAuthNavBar"}
                    onClick={() => navigate(loginPage)}/>
    
                    <KMButton text={"Register"} onClick={()=>navigate(registerPage)} upperCase/>
                </Grid>
            )
        },
        [loginPage]: {
            opacity: 1,
            endButtons: (
                <Grid item>
                    <KMButton text={"Sign Up"} onClick={() => navigate(registerPage)} upperCase/>
                </Grid>
            )
        },
        [landingPage]: {
            opacity: 0.8,
            endButtons: (
                <Grid item>
                    <KMButton text={"Login"} bold upperCase
                    variant={"nonAuthNavBar"}
                    onClick={() => navigate(loginPage)}/>
    
                    <KMButton text={"Register"} onClick={()=>navigate(registerPage)} upperCase/>
                </Grid>
            )
        },
    }

    const { isTabletLanding, resDesignLanding } = useContext(ResponsiveContext);
    const navBarProps = navBarStyles?.[location] || navBarStyles.default
    return (
        <Grid container style={{ width: "100%",
            height: resDesignLanding(64, 64, 60),
            backgroundColor: `rgba(255, 215, 111, ${navBarProps.opacity})`,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <Grid item>
                <Grid container>
                    <Grid item style={{
                        marginLeft: "20px",
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <img src={Untitled} style={{ height: resDesignLanding(35, 35, 25) }}/>
                    </Grid>

                    {isTabletLanding && links.map(({ name, handleClick }) => (
                        <Grid item key={name}>
                            <KMButton text={name} bold upperCase
                            onClick={handleClick}
                            variant={"nonAuthNavBar"}/>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            
            {navBarProps.endButtons}
        </Grid>
    )
}

export default NonAuthNavBar