import { Grid, Typography } from "@mui/material"
import axios from "../../api/axios"
import useAccessTokenInfo from "../../hooks/useAccessTokenInfo"
import useActionCenter from "../../hooks/useActionCenter"
import { ACTION_DISMISS_URL } from "../../pages/URLs"
import ActionCenterCard from "../IndividualComponents/ActionCenterCard"
import NotificationsIcon from '@mui/icons-material/Notifications';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FaceIcon from '@mui/icons-material/Face';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import SavingsIcon from '@mui/icons-material/Savings';
import SearchIcon from '@mui/icons-material/Search';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

const iconBaseStyle = {
    fontSize: "2vh",
    marginLeft: "1vw",
    marginRight: "1vw"
}

const actionCenterIcons = {
    "matchFound": <FavoriteIcon style={{
        ...iconBaseStyle,
        fill: "red"
    }}/>,
    "updateProfile": <FaceIcon style={{
        ...iconBaseStyle,
        fill: "purple"
    }}/>,
    "addInterests": <LocalFloristIcon style={{
        ...iconBaseStyle,
        fill: "green"
    }}/>,
    "activateSubscription": <SavingsIcon style={{
        ...iconBaseStyle,
        fill: "black"
    }}/>,
    "useSearches": <SearchIcon style={{
        ...iconBaseStyle,
        fill: "black"
    }}/>,
    "upgradeSubscription": <ArrowCircleUpIcon style={{
        ...iconBaseStyle,
        fill: "red"
    }}/>,
}

const ActionCenter = () => {

    const info = useAccessTokenInfo()
    const user = info?.user || info.email
    const [notifs, setNotifs] = useActionCenter(user)

    if (!notifs) {
        return <pre>Loading...</pre>
    }

    const notifArr = Object.keys(notifs)

    const dismiss = async (notifVar) => {
        await axios.post(ACTION_DISMISS_URL, 
            JSON.stringify({ user: user, notifVar: notifVar }),
            {
                headers: { 'Content-Type': 'application/json',
                'x-functions-key': `${process.env.REACT_APP_ACTION_DISMISS_KEY}` },
                withCredentials: true
            }
        ).then((res) => {
            delete notifs[notifVar]
            setNotifs({...notifs})
        })
    }

    return (
        <Grid container
        sx={{
            px: "1vw",
            pt: "1vh"
        }}
        >
            <Grid item xs={12}>
                <Typography variant="h4">
                    Action Center
                </Typography>
            </Grid>
            {notifArr.map((notif, index) => {
                return (
                    <ActionCenterCard 
                    key={index}
                    text={notifs[notif]}
                    variable={notif}
                    dismiss={dismiss}
                    icon={actionCenterIcons[notif]
                        || <NotificationsIcon style={{fontSize: "2vh"}}
                    sx={{mx: "1vw"}}
                    />}
                    />
                )
            })}
        </Grid>
    )
}

export default ActionCenter