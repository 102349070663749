import { Grid, Typography } from "@mui/material"
import { createRef, useRef } from "react"
import MainBody from "../../Layout/MainBody"

const TitleTextPageLayout = ({ titleTextArray, headerTextObj, footerTextObj }) => {
    
    const refs = useRef([])
    titleTextArray.forEach(() => {
        refs.current.push(createRef())
    })

    return (
        <MainBody>
            <Grid item xs={12}
            sx={{
                px: "2vw",
                py: "3vh"
            }}
            >
                <Typography variant="h3"
                style={{
                    fontWeight: "bold"
                }}>
                    {headerTextObj.title}
                </Typography>

                <Typography variant="h6">
                    {headerTextObj.introduction}
                </Typography>

                <ol style={{ paddingLeft: "1vw" }}>
                {
                    titleTextArray.map((info, index) => (
                        <li onClick={() => {
                            refs.current[index].current.scrollIntoView()
                        }}
                        style={{
                            color: "black",
                            textDecoration: "underline",
                            cursor: "pointer",
                            fontSize: "2vh"
                        }}>
                            {info.title}
                        </li>
                    ))
                }
                </ol>

                {
                    titleTextArray.map((info,index) => (
                        <>
                        <Typography variant="h5"
                        style={{
                            fontFamily: "Quicksand",
                            fontWeight: "bold"
                        }}
                        ref={refs.current[index]}
                        sx={{
                            pt: "4vh"
                        }}
                        >
                            {index+1}. {info.title}
                        </Typography>
                        <Typography variant="h6">
                            {info.text}
                        </Typography>
                        </>
                    ))
                }

                <Typography variant="h4"
                sx={{
                    pt: "4vh"
                }}
                style={{
                    fontWeight: "bold"
                }}>
                    {footerTextObj.title}
                </Typography>

                <Typography variant="h6">
                    {footerTextObj.conclusion}
                </Typography>
            </Grid>
        </MainBody>
    )
}

export default TitleTextPageLayout