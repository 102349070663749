import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import { landingPage, registerPage, FORGET_PASSWORD_URL } from './URLs';
import { Grid, TextField, Typography } from '@mui/material';
import NonAuthNavBar from '../components/NavBar/NonAuthNavBar';
import { mainColors } from '../components/Theme/Colors/palette';
import desktopLogin from '../img/desktopLogin-min.jpg'
import Footer from '../components/CombinedComponents/Footer';
import ResponsiveContext from '../ResponsiveDesign/ResponsiveProvider';
import KMButton from '../components/IndividualComponents/KMButton';

const ForgotPassword = () => {

    const { isTabletLanding } = useContext(ResponsiveContext)

    const navigate = useNavigate();

    const links = [
        {
            name: "Home",
            handleClick: () => navigate(landingPage)
        }
    ]

    const [user, setUser] = useState('');
    const [success, setSuccess] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setErrMsg('');
    }, [user])

    const handleSubmit = async (e) => {
        if (!user) {
            setErrMsg("Email and password are required")
            return
        }

        setErrMsg("")
        setLoading(true)

        try {
            await axios.post(FORGET_PASSWORD_URL, 
                JSON.stringify({ email: user }),
                {
                    headers: { 'Content-Type': 'application/json',
                    'x-functions-key': `${process.env.REACT_APP_FORGOT_PASSWORD}` },
                    withCredentials: true
                }
            ).then(res => {
                if (res?.data === "Done") {
                    setTimeout(() => {
                        setSuccess(true)
                        setLoading(false)
                    }, 1500)
                } else {
                    setTimeout(() => {
                        setErrMsg("Something went wrong")
                        setLoading(false)
                    }, 1500)
                }
            })
        } catch (err) {
            if (!err?.response) {
                setTimeout(() => {
                    setErrMsg('No Server Response');
                    setLoading(false)
                }, 1500)
            } else if (err.response?.status === 400) {
                setTimeout(() => {
                    setErrMsg(err.response.data);
                    setLoading(false)
                }, 1500)
            } else if (err.response?.status === 500) {
                setTimeout(() => {
                    setErrMsg('Unexpected error, please try again later');
                    setLoading(false)
                }, 1500)
            } else {
                setTimeout(() => {
                    setErrMsg('Login Failed');
                    setLoading(false)
                }, 1500)
            }
        }

        }


    return (

        <Grid container height="100vh"
        direction="column"
        >

            <Grid item height="auto">
                <NonAuthNavBar links={links}/>
            </Grid>

            <Grid item
            width="100vw"
            xs={true}
            >

            <Grid container
            direction="row"
            height="100%"
            >

                <Grid item xs={ isTabletLanding ? true : 12 } sx={{mx:"6vw"}}
                display="flex"
                alignItems="center"
                height="auto"
                >

                    <Grid container spacing={"2vh"}>

                        { !success ? 
                        (<>
                        <Grid item xs={12}>
                            <Typography variant="h2">
                                Forgot Password
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h5">
                                If we have your email in our database, you will shortly receive a reset password email.
                            </Typography>
                        </Grid>

                        <Grid item xs={4}
                        display="flex"
                        alignItems="center"
                        >
                            <Typography variant="h4">
                                Email
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                        display="flex"
                        justifyContent="flex-end"
                        >
                            <TextField size="small"
                            value={user}
                            onChange={(e) => {
                                setUser(e.target.value)
                            }}
                            fullWidth/>
                        </Grid>

                        <Grid item xs={12}
                        style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center"
                        }}>
                            <KMButton endArrow
                            variant={"impTitle"}
                            text={"Send"}
                            onClick={handleSubmit}
                            loading={loading}/>
                        </Grid>

                        <Grid item>
                            <Typography variant="h6" style={{ color: "red" }}>
                                {errMsg}
                            </Typography>
                        </Grid>
                        </>) : (<>
                        <Grid item xs={12}>
                            <Typography variant="h2">
                                Success
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h5">
                                Please check your email for further instructions.
                            </Typography>
                        </Grid>
                        </>)}

                    </Grid>

                </Grid>

                { isTabletLanding && <Grid item xs={true}
                height="auto"
                >
                    <Grid container
                    style={{backgroundImage: `url(${desktopLogin})`,
                    backgroundSize: "cover",
                    height: '100%',
                    backgroundPosition: "center"
                    }}
                    >
                        <Grid container
                        style={{
                            width: "5vw",
                            height: "100%"}}
                        sx={{bgcolor: "rgba(255, 215, 111, 0.6)"}}
                        >
                        </Grid>
                    </Grid>
                </Grid>}



            </Grid>
            </Grid>

            <Grid item height="auto"
            sx={{bgcolor: mainColors.primary,
            px:"8vw", py:"1.5vh"
            }}
            >
                <Footer/>
            </Grid>


        </Grid>

    )
}

export default ForgotPassword
