import TitleTextPageLayout from "../components/CombinedComponents/TitleTextPageLayout";

const introText = {
    title: "Contact Us",
    introduction: "Contact form coming soon! Please send all enquiries to admin@krishnamarriage.com"
}

const body = []

const footerText = {
    title: "Thank you for reading",
    conclusion: "We hope you are enjoying the experience of Krishna Marriage"
}


const ContactUs = () => {
    return (
        <TitleTextPageLayout titleTextArray={body} headerTextObj={introText} footerTextObj={footerText}/>
    )
}

export default ContactUs
