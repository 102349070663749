import { Avatar, Button, Grid, Modal, Typography } from "@mui/material"
import CottageIcon from '@mui/icons-material/Cottage';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { mainColors } from "../Theme/Colors/palette";
import DispPills from "../IndividualComponents/DispPills";
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import SchoolIcon from '@mui/icons-material/School';
import WestIcon from '@mui/icons-material/West';
import FadeInNormal from "../../Animation/FadeInNormal";
import { motion } from "framer-motion";
import HomeIcon from '@mui/icons-material/Home';
import FlagIcon from '@mui/icons-material/Flag';
import PublicIcon from '@mui/icons-material/Public';
import LanguageIcon from '@mui/icons-material/Language';
import { useEffect, useState } from "react";
import { Favorite, Search, Work } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { GET_PICS_URL, homePage, MATCH_RESULT_URL } from "../../pages/URLs";
import useAccessTokenInfo from "../../hooks/useAccessTokenInfo";
import axios from "../../api/axios";
import DragAndDropCompMatch from "./DragAndDropCompMatch";
import BadgeIcon from '@mui/icons-material/Badge';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import WorkIcon from '@mui/icons-material/Work';
import ConstructionIcon from '@mui/icons-material/Construction';
import KMButton from "../IndividualComponents/KMButton";
import useRefreshToken from "../../hooks/useRefreshToken";

const FullMatch = (props) => {

    const refresh = useRefreshToken();

    const matchingData = props.matchingData

    const [avatarImage, setAvatarImage] = useState("");

    useEffect(() => {
        axios.post(GET_PICS_URL, 
            JSON.stringify({ email: matchingData.user, picKey: "profilePic" }),
            {
                headers: { 'Content-Type': 'application/json',
                'x-functions-key': `${process.env.REACT_APP_GET_PICS_KEY}` },
                withCredentials: true
            }
        ).then(res => {
            if (res.data != "None" && res.data?.src) {
                setAvatarImage(res.data.src)
            }
        })
    }, [])

    const navigate = useNavigate()
    const accessTokenEmail = useAccessTokenInfo("email")

    const [images, setImages] = useState([]);


    const [acceptedModal, setAcceptedModal] = useState(false);
    const [rejectedModal, setRejectedModal] = useState(false);

    const accepted = async () => {
        await axios.post(MATCH_RESULT_URL, 
            JSON.stringify({ user: accessTokenEmail,
            likeOrDislike: "1"
            }),
            {
                headers: { 'Content-Type': 'application/json',
                'x-functions-key': `${process.env.REACT_APP_MATCHING_RESULT_KEY}` },
                withCredentials: true
            }
        )
        setAcceptedModal(true)
    }
    const rejected = async () => {
        await axios.post(MATCH_RESULT_URL, 
            JSON.stringify({ user: accessTokenEmail,
            likeOrDislike: "-1"
            }),
            {
                headers: { 'Content-Type': 'application/json',
                'x-functions-key': `${process.env.REACT_APP_MATCHING_RESULT_KEY}` },
                withCredentials: true
            }
        )
        setRejectedModal(true)
    }

    const fadeDuration = 0.5

    return (
        <>
        <Grid container
        sx={{px:"2vw", py:"5vh"}}
        width="100%"
        >
            <FadeInNormal duration={fadeDuration}>

            <Grid item xs={12} height="auto"
            
            >
                <Grid container direction="row"
                display="flex"
                alignItems="center"
                >
                    <Grid item width="auto"
                    display="flex"
                    justifyContent="left"
                    alignItems="center"
                    sx={{mr:"2vw"}}
                    >
                        <Avatar
                        sx={{height: "18vh", width: "18vh"}}
                        src={avatarImage}
                        >
                            {/* <Typography variant="h3">
                                {(matchingData.matchName[0] + matchingData.matchName[1]).toUpperCase()}
                            </Typography> */}
                        </Avatar>
                    </Grid>
                    <Grid item xs={6}
                    >
                        <Typography variant="h3" sx={{mb:"1vh"}}>
                            {matchingData?.matchName || ""}
                        </Typography>
                        <Typography variant="h4" sx={{mb:"1vh"}}>
                            {matchingData?.oneLiner || ""}
                        </Typography>
                        <Typography variant="h6">
                            Height: {matchingData?.height || ""}m
                        </Typography>
                        <Typography variant="h6">
                            Age: {matchingData?.age || ""}
                        </Typography>
                        <Typography variant="h6">
                            Gender: {matchingData?.gender || ""}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            </FadeInNormal>

            <FadeInNormal duration={fadeDuration}>

            <Grid item xs={12}
            sx={{mt:"3vh"}}
            >
                <Typography variant="h4" sx={{mb:"1.4vh", mt:"0.7vh"}}>
                    My Preferences are to...
                </Typography>
                <Typography variant="h6"
                display="flex"
                alignItems="center"
                >
                    <CottageIcon sx={{mr:"1.4vw", fill: mainColors.purpleAccent}}/> {matchingData?.livingSituation || ""}
                </Typography>
                <Typography variant="h6"
                display="flex"
                alignItems="center"
                >
                    <FamilyRestroomIcon sx={{mr:"1.4vw", fill: mainColors.purpleAccent}}/> Have {matchingData?.children || ""} children
                </Typography>
                <Typography variant="h6"
                display="flex"
                alignItems="center"
                >
                    <TravelExploreIcon sx={{mr:"1.4vw", fill: mainColors.purpleAccent}}/> {matchingData?.relocation || ""}
                </Typography>
            </Grid>
            </FadeInNormal>

            <FadeInNormal duration={fadeDuration}>

            <Grid item xs={12}
            sx={{mt:"3vh"}}
            >
                <Typography variant="h4" sx={{mb:"2vh"}}>
                    and I am looking for someone who is...
                </Typography>
                <DispPills array={matchingData?.pillPrefs || ["None"]} color={mainColors.green}/>
            </Grid>
            </FadeInNormal>

            <FadeInNormal duration={fadeDuration}>
            <Grid item xs={12}
            sx={{mt:"3vh"}}
            >
                <Typography variant="h4" sx={{mb:"1.4vh", mt:"0.7vh"}}>
                    My Spiritual Life
                </Typography>
                <Typography variant="h6"
                display="flex"
                alignItems="center"
                >
                    <SelfImprovementIcon sx={{mr:"1.4vw", fill: mainColors.purpleAccent}}/> {matchingData?.japa || ""} rounds per day
                </Typography>
                <Typography variant="h6"
                display="flex"
                alignItems="center"
                >
                    <TroubleshootIcon sx={{mr:"1.4vw", fill: mainColors.purpleAccent}}/> Following {matchingData?.principles || ""} regulative principles
                </Typography>
                <Typography variant="h6"
                display="flex"
                alignItems="center"
                >
                    <LocalDiningIcon sx={{mr:"1.4vw", fill: mainColors.purpleAccent}}/> {matchingData?.offering || ""} offers food at home
                </Typography>
                <Typography variant="h6"
                display="flex"
                alignItems="center"
                >
                    <SchoolIcon sx={{mr:"1.4vw", fill: mainColors.purpleAccent}}/> Is {matchingData?.initiation_status || ""}
                </Typography>
            </Grid>
            </FadeInNormal>

            <FadeInNormal duration={fadeDuration}>

            <Grid item xs={12}
            sx={{mt:"3vh"}}
            >
                <Typography variant="h4" sx={{mb:"2vh"}}>
                    I have completed these courses...
                </Typography>
                <DispPills array={matchingData?.studies || ["None"]} color={mainColors.green}/>
            </Grid>
            </FadeInNormal>

            <FadeInNormal duration={fadeDuration}>
            <Grid item xs={12}
            sx={{mt:"3vh"}}
            >
                <Typography variant="h4" sx={{mb:"2vh"}}>
                    I am engaged in these services...
                </Typography>
                <DispPills array={matchingData?.services || ["None"]} color={mainColors.textColor}/>
            </Grid>
            </FadeInNormal>

            <FadeInNormal duration={fadeDuration}>
            <Grid item xs={12}
            sx={{mt:"3vh"}}
            >
                <Typography variant="h4" sx={{mb:"2vh"}}>
                    I have read these books by Srila Prabhupada...
                </Typography>
                <DispPills array={matchingData?.sp_books || ["None"]} color={mainColors.green}/>
            </Grid>
            </FadeInNormal>

            <FadeInNormal duration={fadeDuration}>
            <Grid item xs={12}
            sx={{mt:"3vh"}}
            >
                <Typography variant="h4" sx={{mt:"1vh"}}>
                    A little bit more about my spiritual life....
                </Typography>
                <Typography variant="h6">
                    {matchingData?.more_info || ""}
                </Typography>
            </Grid>
            </FadeInNormal>

            <FadeInNormal duration={fadeDuration}>
            <Grid item xs={12}
            sx={{mt:"3vh"}}
            >
                <Typography variant="h4" sx={{mb:"1.4vh", mt:"0.7vh"}}>
                    About My Life
                </Typography>
                <Typography variant="h6"
                display="flex"
                alignItems="center"
                >
                    <HomeIcon sx={{mr:"1.4vw", fill: mainColors.purpleAccent}}/> I live in {matchingData?.city || ""}
                </Typography>
                <Typography variant="h6"
                display="flex"
                alignItems="center"
                >
                    <FlagIcon sx={{mr:"1.4vw", fill: mainColors.purpleAccent}}/> My homecity is {matchingData?.homecity || ""}
                </Typography>
                <Typography variant="h6"
                display="flex"
                alignItems="center"
                >
                    <PublicIcon sx={{mr:"1.4vw", fill: mainColors.purpleAccent}}/> I am {matchingData?.ethinicity || ""}
                </Typography>
                <Typography variant="h6"
                display="flex"
                alignItems="center"
                >
                    <LanguageIcon sx={{mr:"1.4vw", fill: mainColors.purpleAccent}}/> I prefer to speak {matchingData?.pref_language || ""}
                </Typography>
            </Grid>
            </FadeInNormal>

            <FadeInNormal duration={fadeDuration}>
            <Grid item xs={12}
            sx={{mt:"3vh"}}
            >
                <Typography variant="h4" sx={{mt:"1vh"}}>
                    A little bit about me
                </Typography>
                <Typography variant="h6">
                    {matchingData?.description || ""}
                </Typography>
            </Grid>
            </FadeInNormal>

            <FadeInNormal duration={fadeDuration}>
            <Grid item xs={12}
            sx={{mt:"3vh"}}
            >
                <Typography variant="h4" sx={{mt:"1vh"}}>
                    My goals are...
                </Typography>
                <Typography variant="h6">
                    {matchingData?.goals || ""}
                </Typography>
            </Grid>
            </FadeInNormal>

            <FadeInNormal duration={fadeDuration}>
            <Grid item xs={12}
            sx={{mt:"3vh"}}
            >
                <Typography variant="h6"
                display="flex"
                alignItems="center"
                >
                    <BadgeIcon sx={{mr:"1.4vw", fill: mainColors.purpleAccent}}/> I am currently {matchingData?.employment_status || ""}
                </Typography>
                {matchingData?.employment_status === "Student" ?
                    (
                    <>
                    <Typography variant="h6"
                    display="flex"
                    alignItems="center"
                    >
                        <SchoolIcon sx={{mr:"1.4vw", fill: mainColors.purpleAccent}}/> I study {matchingData?.subject || ""}
                    </Typography>
                    <Typography variant="h6"
                    display="flex"
                    alignItems="center"
                    >
                        <KeyboardDoubleArrowUpIcon sx={{mr:"1.4vw", fill: mainColors.purpleAccent}}/> My level is {matchingData?.level || ""}
                    </Typography>
                    </>
                    ) :
                    (
                    <>
                    <Typography variant="h6"
                    display="flex"
                    alignItems="center"
                    >
                        <ConstructionIcon sx={{mr:"1.4vw", fill: mainColors.purpleAccent}}/> I work in {matchingData?.industry || ""}
                    </Typography>
                    <Typography variant="h6"
                    display="flex"
                    alignItems="center"
                    >
                        <WorkIcon sx={{mr:"1.4vw", fill: mainColors.purpleAccent}}/> I work as {matchingData?.job_title || ""}
                    </Typography>
                    </>
                    )
                }
            </Grid>
            </FadeInNormal>

            <FadeInNormal duration={fadeDuration}>
            <Grid item xs={12}
            sx={{mt:"3vh"}}
            >
                <DragAndDropCompMatch images={images} setImages={setImages} user={matchingData?.user || ""}/>
            </Grid>
            </FadeInNormal>

            <Grid item xs={12}
            display="flex"
            justifyContent="space-between"
            sx={{mt:"4vh"}}
            >
                <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, delay: 2.6 }}
                viewport={{ once: true }}
                layout
                >

                <Button
                startIcon={<WestIcon style={{fontSize:"3vh", fill: mainColors.textColor}}/>}
                onClick={rejected}
                >
                    <Typography variant="h5"
                    color={mainColors.textColor}
                    >
                        Keep Searching
                    </Typography>
                </Button>
                </motion.div>

                <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.6 }}
                viewport={{ once: true }}
                layout
                >
                    <KMButton endArrow
                    variant={"impTitle"}
                    text={"Match Us"}
                    onClick={accepted}/>
                </motion.div>
            </Grid>


        </Grid>
        <Modal
        open={acceptedModal}
        >
            <Grid container
            style={{
                height: "100vh",
                width: "100vw"
            }}
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
            >
                <Grid item
                style={{
                    background: mainColors.background,
                    minHeight: "30vh",
                    width: "25vw",
                    borderRadius: 40,
                }}
                sx={{
                    px:"2vw",
                    pt:"2vh",
                    pb:"3vh"
                }}
                >
                    <Grid container>
                        <Grid item xs={12}
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                        >
                            <Favorite
                            style={{
                                fill: "red",
                                fontSize: "10vh"
                            }}
                            />
                        </Grid>
                        <Grid item xs={12}
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                        >
                            <Typography
                            fontSize={"3vh"}
                            >
                                We have sent your like
                            </Typography>
                        </Grid>
                        <Grid item xs={12}
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                        >
                            <Typography
                            fontSize={"2vh"}
                            >
                                Keep an eye on your 
                            </Typography>
                        </Grid>
                        <Grid item xs={12}
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                        >
                            <Typography
                            fontSize={"2vh"}
                            >
                                Actions Center for updates
                            </Typography>
                        </Grid>
                        <Grid item xs={12}
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{ mt: "1vh" }}
                        >
                            <KMButton text={"Continue"} onClick={async () => {
                                await refresh()
                                navigate(homePage)
                            }}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
        <Modal
        open={rejectedModal}
        >
            <Grid container
            style={{
                height: "100vh",
                width: "100vw"
            }}
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
            >
                <Grid item
                style={{
                    background: mainColors.background,
                    minHeight: "30vh",
                    width: "25vw",
                    borderRadius: 40,
                }}
                sx={{
                    px:"2vw",
                    pt:"2vh",
                    pb:"3vh"
                }}
                >
                    <Grid container>
                        <Grid item xs={12}
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                        >
                            <Search
                            style={{
                                fill: "black",
                                fontSize: "10vh"
                            }}
                            />
                        </Grid>
                        <Grid item xs={12}
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                        >
                            <Typography
                            fontSize={"3vh"}
                            >
                                We will keep searching for you
                            </Typography>
                        </Grid>
                        <Grid item xs={12}
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                        >
                            <Typography
                            fontSize={"2vh"}
                            >
                                We have made a note of your
                            </Typography>
                        </Grid>
                        <Grid item xs={12}
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                        >
                            <Typography
                            fontSize={"2vh"}
                            >
                                decision, you can look back at this
                            </Typography>
                        </Grid>
                        <Grid item xs={12}
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                        >
                            <Typography
                            fontSize={"2vh"}
                            >
                                profile in your Activity Feed 
                            </Typography>
                        </Grid>
                        <Grid item xs={12}
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{ mt: "1vh" }}
                        >
                            <KMButton text={"Continue"} onClick={async () => {
                                await refresh()
                                navigate(homePage)
                            }}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
        </>
    )
}

export default FullMatch