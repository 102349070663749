import { Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { registerPage } from "../../pages/URLs"
import { useContext } from "react"
import ResponsiveContext from "../../ResponsiveDesign/ResponsiveProvider"
import KMButton from "../IndividualComponents/KMButton"

const Testimonials = () => {

    const { isTabletLanding } = useContext(ResponsiveContext)

    const navigate = useNavigate()

    return (
        <Grid container>

            <Grid item xs={12}>
                <Typography variant="h2"
                sx={{mb:"4vh"}}
                >
                    What have others said...
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Grid container
                spacing={"6vw"}
                >

                    <Grid item xs={ isTabletLanding ? 6 : 12 }>
                        <Grid container
                        spacing={"5vh"}
                        >

                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    Amrita Vilasini Devi Dasi & Ram Lakshman Das (UK)
                                </Typography>
                                <Typography variant="h6">
                                    The whole process of introduction went really smoothly. We were offered professional assistance, including personal meetings. That made us feel confident about being 'in good hands'. We highly recommend the Krishna Marriage portal to everyone looking for a spouse.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    Nirav (Germany) and Anuradha Devi Dasi (Ukraine)
                                </Typography>
                                <Typography variant="h6">
                                We recommend Krishna Marriage to everyone as it is really a good opportunity to meet a perfect match, as it was in our case… We pay deep respect and appreciation for the great work which Krishna Marriage is doing for all devotees around the world!
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item xs={ isTabletLanding ? 6 : 12 }>
                        <Grid container
                        spacing={"5vh"}
                        >

                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    Guru Bhakti Devi Dasi & Saranga Thakur Das (UK)
                                </Typography>
                                <Typography variant="h6">
                                    We would like to thank Krishna Marriage for introducing us to each other. We feel blessed to raise a Krishna conscious family. We are just celebrating our 4th wedding anniversary and our wonderful daughter Palika will have her 3rd birthday soon.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    Hema & Anish (UK)
                                </Typography>
                                <Typography variant="h6">
                                    When I joined the Krishna Marriage project, the matchmaker who I contacted was very helpful, understanding and personal. The matchmakers at Krishna Marriage tried their best to fulfil my needs and were very approachable. We are both very grateful to the matchmakers at Krishna Marriage. Thank you very much for everything!
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item xs={12}
            sx={{mt:"4vh"}}
            display="flex"
            justifyContent="flex-end"
            >
                <KMButton endArrow
                text={"Become a success story now"}
                variant={"impTitle"}
                onClick={()=>navigate(registerPage)}/>
            </Grid>

        </Grid>
    )
}

export default Testimonials