import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../api/axios';
import { homePage, landingPage, registerPage, FORGET_PASSWORD_URL, RESET_PASSWORD_URL } from './URLs';
import { Grid, TextField, Typography } from '@mui/material';
import NonAuthNavBar from '../components/NavBar/NonAuthNavBar';
import { mainColors } from '../components/Theme/Colors/palette';
import desktopLogin from '../img/desktopLogin-min.jpg'
import Footer from '../components/CombinedComponents/Footer';
import ResponsiveContext from '../ResponsiveDesign/ResponsiveProvider';
import KMButton from '../components/IndividualComponents/KMButton';

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const pwdMessage = (
    <>
        Password must be between 8 to 24 characters and contain
        <br/>
        Capital letter
        <br/>
        Lowercase letter
        <br/>
        Number
        <br/>
        One of these special characters: !@#$%
    </>
)

const ResetPassword = () => {

    const { forgotPasswordToken } = useParams()

    const { isTabletLanding } = useContext(ResponsiveContext)

    const navigate = useNavigate();

    const links = [
        {
            name: "Home",
            handleClick: () => navigate(landingPage)
        }
    ]

    const [pwd, setPwd] = useState('');
    const [matchPwd, setMatchPwd] = useState('');
    const [success, setSuccess] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setErrMsg('');
    }, [pwd, matchPwd])

    const handleSubmit = async (e) => {
        if (!pwd || !matchPwd) {
            setErrMsg("Password and confirm password cannot be empty")
            return
        }

        if (!PWD_REGEX.test(pwd)) {
            setErrMsg(pwdMessage)
            return
        }

        if (pwd !== matchPwd) {
            setErrMsg("Passwords must match")
            return
        }

        setErrMsg("")
        setLoading(true)

        try {
            await axios.post(RESET_PASSWORD_URL, 
                JSON.stringify({ forgotPasswordToken, pwd }),
                {
                    headers: { 'Content-Type': 'application/json',
                    'x-functions-key': `${process.env.REACT_APP_RESET_PASSWORD}` },
                    withCredentials: true
                }
            ).then(res => {
                if (res?.data === "Done") {
                    setTimeout(() => {
                        setSuccess(true)
                        setLoading(false)
                    }, 1500)
                } else {
                    setTimeout(() => {
                        setErrMsg("Something went wrong")
                        setLoading(false)
                    }, 1500)
                }
            })
        } catch (err) {
            if (!err?.response) {
                setTimeout(() => {
                    setErrMsg('No Server Response');
                    setLoading(false)
                }, 1500)
            } else if (err.response?.status === 400) {
                setTimeout(() => {
                    setErrMsg(err.response.data);
                    setLoading(false)
                }, 1500)
            } else if (err.response?.status === 500) {
                setTimeout(() => {
                    setErrMsg('Unexpected error, please try again later');
                    setLoading(false)
                }, 1500)
            } else {
                setTimeout(() => {
                    setErrMsg('Reset password failed, probably due to incorrect token');
                    setLoading(false)
                }, 1500)
            }
        }

        }


    return (

        <Grid container height="100vh"
        direction="column"
        >

            <Grid item height="auto">
                <NonAuthNavBar links={links}/>
            </Grid>

            <Grid item
            width="100vw"
            xs={true}
            >

            <Grid container
            direction="row"
            height="100%"
            >

                <Grid item xs={ isTabletLanding ? true : 12 } sx={{mx:"6vw"}}
                display="flex"
                alignItems="center"
                height="auto"
                >

                    <Grid container spacing={"2vh"}>

                        { !success ? 
                        (<>
                        <Grid item xs={12}>
                            <Typography variant="h2">
                                Reset Password
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h5">
                                Please enter your new password.
                            </Typography>
                        </Grid>

                        <Grid item xs={4}
                        display="flex"
                        alignItems="center"
                        >
                            <Typography variant="h4">
                                Password
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                        display="flex"
                        justifyContent="flex-end"
                        >
                            <TextField size="small"
                            value={pwd}
                            onChange={(e) => {
                                setPwd(e.target.value)
                            }}
                            type='password'
                            fullWidth/>
                        </Grid>

                        <Grid item xs={4}
                        display="flex"
                        alignItems="center"
                        >
                            <Typography variant="h4">
                                Confirm password
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                        display="flex"
                        justifyContent="flex-end"
                        >
                            <TextField size="small"
                            value={matchPwd}
                            onChange={(e) => {
                                setMatchPwd(e.target.value)
                            }}
                            type='password'
                            fullWidth/>
                        </Grid>

                        <Grid item xs={12}
                        style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center"
                        }}>
                            <KMButton endArrow
                            variant={"impTitle"}
                            text={"Reset"}
                            onClick={handleSubmit}
                            loading={loading}/>
                        </Grid>

                        <Grid item>
                            <Typography variant="h6" style={{ color: "red" }}>
                                {errMsg}
                            </Typography>
                        </Grid>
                        </>) : (<>
                        <Grid item xs={12}>
                            <Typography variant="h2">
                                Success
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h5">
                                Password changed. Please try logging in again.
                            </Typography>
                        </Grid>
                        </>)}

                    </Grid>

                </Grid>

                { isTabletLanding && <Grid item xs={true}
                height="auto"
                >
                    <Grid container
                    style={{backgroundImage: `url(${desktopLogin})`,
                    backgroundSize: "cover",
                    height: '100%',
                    backgroundPosition: "center"
                    }}
                    >
                        <Grid container
                        style={{
                            width: "5vw",
                            height: "100%"}}
                        sx={{bgcolor: "rgba(255, 215, 111, 0.6)"}}
                        >
                        </Grid>
                    </Grid>
                </Grid>}



            </Grid>
            </Grid>

            <Grid item height="auto"
            sx={{bgcolor: mainColors.primary,
            px:"8vw", py:"1.5vh"
            }}
            >
                <Footer/>
            </Grid>


        </Grid>

    )
}

export default ResetPassword
