import { Grid, IconButton } from "@mui/material"
import { motion } from "framer-motion"
import { useContext } from "react"
import LayoutContext from "../../../Layout/LayoutProvider"
import NavBarContext from "../Context/NavBarProvider"
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import ResponsiveContext from "../../../ResponsiveDesign/ResponsiveProvider"

const Base = ({ children }) => {

    const { isLaptop } = useContext(ResponsiveContext)
    const { navBarWidth, navPicWidth } = useContext(LayoutContext)
    const { open, navBarDuration, toggleNavBar, paddingTop, heightOfAvatar, navToggleDisabled } = useContext(NavBarContext)

    return (
        <div style={{
            width: navPicWidth,
            position: "fixed",
            left: 0,
            display: "flex",
            justifyContent: "end"
        }}>
            <motion.div style={{ height: "100vh" }}
            initial={{ width: navBarWidth }}
            animate={{ width: open ? navPicWidth : navBarWidth }}
            transition={{ duration: navBarDuration/1000 }}>
                <Grid container
                display="flex"
                flexDirection="column"
                alignItems="start"
                style={{height: "100%"}}
                sx={{bgcolor: "rgba(255, 215, 111, 0.8)"}}>
                    <motion.div initial={{ height: "100%", width: navBarWidth }}
                    animate={{ width: open ? `calc(${navBarWidth} + 2px)` : navBarWidth }}
                    transition={{ duration: navBarDuration/1000 }}>
                        <Grid item
                        sx={{mt:`${paddingTop}px`}}
                        style={{ width: "100%" }}
                        display="flex"
                        justifyContent="center">
                            <motion.div initial={{
                                height: `${heightOfAvatar}px`,
                                width: `${heightOfAvatar}px`,
                                marginLeft: `calc( calc( ${navBarWidth} / 2 ) - ${heightOfAvatar/2}px )`,
                                rotate: isLaptop ? "0deg" : "180deg",
                                marginLeft: "0px"
                            }}
                            animate={ isLaptop ? { rotateY: open ? "180deg" : "0deg", marginLeft: open ? "14px" : "0px" } : {} }
                            transition={{ duration: navBarDuration/1000 }}
                            style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center"
                            }}>
                                <IconButton onClick={toggleNavBar}
                                disabled={navToggleDisabled}>
                                    <KeyboardDoubleArrowLeftIcon
                                    style={{height: "40px", width: "40px", fill: "black"}}/>
                                </IconButton>
                            </motion.div>
                        </Grid>
                    </motion.div>

                    {children}
                </Grid>
            </motion.div>
        </div>
    )
}

export default Base