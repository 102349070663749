import { Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { registerPage } from "../../pages/URLs"
import { motion } from "framer-motion"
import KMButton from "../IndividualComponents/KMButton"

const OurServices = () => {

    const navigate = useNavigate()

    return (
        <Grid container>
            
            <Grid item xs={12}
            display="flex"
            justifyContent="left"
            sx={{mb:"5vh"}}
            >
                <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                >
                    <Typography variant="h1">
                        15 years of service...
                    </Typography>
                </motion.div>
            </Grid>

            <Grid item xs={12}
            display="flex"
            justifyContent="center"
            sx={{mb:"3vh"}}
            >
                <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ delay: 0.3, duration: 0.5 }}
                viewport={{ once: true }}
                >
                    <Typography variant="h4" textAlign="justify">
                    Krishna Marriage has been serving the global community of ISKCON for more than 15 years with the aim of creating lifelong relationships between devotees. Finding a suitable match can be a daunting task and Krishna Marriage is here to help you on your journey to finding a suitable candidate with whom you can share memories of joy.
                    </Typography>
                </motion.div>
            </Grid>

            <Grid item xs={12}
            display="flex"
            justifyContent="center"
            sx={{mb:"5vh"}}
            >
                <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ delay: 0.3, duration: 0.5 }}
                viewport={{ once: true }}
                >
                    <Typography variant="h4" textAlign="justify">
                    Our platform and team works hard to provide you with potential matches and allows you to connect with them, start a conversation and spark the fire. Krishna Marriage is available to all countries around the world and you only need an email address to register. We will then ask you to tell us a little bit about yourself, which empowers our platform and matchmakers to search for the best matches.
                    </Typography>
                </motion.div>
            </Grid>

            <Grid item xs={12}
            display="flex"
            justifyContent="right"
            >
                <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 0.5 }}
                viewport={{ once: true }}
                >
                    <KMButton endArrow
                    text={"Spark the fire now"}
                    variant={"accentTitle"}
                    onClick={()=>navigate(registerPage)}/>
                </motion.div>
            </Grid>

        </Grid>
    )
}

export default OurServices

