import { Grid } from "@mui/material"
import { motion } from "framer-motion"
import { mainColors } from "../Theme/Colors/palette"
import React, { useState, useMemo } from "react"

const generator = (n) => {
    const numArr = [...Array(n).keys()]
    const pathsArr = numArr.map(() => {
        return {
            width: Math.random()*0.5 + 0.5,
            length: 1,
            scale: Math.random()*0.2 + 1.2,
            color: Math.floor(Math.random() * 3),
            offset: Math.random() * 2,
            translatex: Math.random() * 3,
            translatey: Math.random() * 2,
            opacity: Math.random() * 0.3 + 0.7,
        }
    })
    return pathsArr
}

const generatorSparklez = (n) => {
    const numArr = [...Array(n).keys()]
    const pathsArr = numArr.map(() => {
        return {
            cx: Math.random() * 187.8,
            cy: Math.random() * 92.8,
            radius: Math.random() * 0.5,
            color: Math.floor(Math.random() * 3),
            delay: Math.random() * 7 + 1,
            duration: Math.random()*2 + 2,
            opacity: Math.random()
        }
    })
    return pathsArr
}

const colors = [ mainColors.background, mainColors.primary, "#fff"]

const numOfLoops = 15
const numOfSparklez = 40

const HeroAnimation = ({
    height, 
    enter,
    startDelay,
    mergeAnimationLength,
    strikeThroughLineDuration,
    strikeThroughOffset,
    textAnimationDuration,
    textOffset,
    diamondOffset,
    glintDelay,
    glintDuration,
    textFadeDuration,
    lineClosingDuration,
    scaleDownDuration
}) => {

    const loops = useMemo(() => generator(numOfLoops), [numOfLoops])
    const dots = useMemo(() => generatorSparklez(numOfSparklez), [numOfSparklez])

    const [animate, setAnimate] = useState(false);
    const [showLoops, setShowLoops] = useState(true);

    setTimeout(() => {
        setAnimate(true)
    }, startDelay)
    setTimeout(() => {
        setShowLoops(false)
    }, startDelay+mergeAnimationLength)

    const textVariants = {
        startState: {
            fontSize: "min(11vh, 7vw)",
            opacity: 0,
            letterSpacing: "min(0.5vh, 0.5vw)"
        },
        fadein: {
            fontSize: "min(11vh, 7vw)",
            opacity: 1,
            letterSpacing: "min(1vh, 1vw)",
            transition: {
                duration: textAnimationDuration/1000,
                delay: startDelay/1000 + mergeAnimationLength/1000 + strikeThroughLineDuration/1000 - strikeThroughOffset/1000 - textOffset/1000,
                ease: "linear"
            }
        },
        startState2: {
            fontSize: "min(11vh, 7vw)",
            opacity: 1,
            letterSpacing: "min(1vh, 1vw)"
        },
        fadeout: {
            fontSize: "min(11vh, 7vw)",
            opacity: 0,
            letterSpacing: "min(1vh, 1vw)",
            transition: {
                duration: textFadeDuration/1000,
                ease: "linear"
            }
        }
    }

    const lineVariants = {
        startState: {
            strokeDasharray: "0 2",
            strokeWidth: 20
        },
        drawin: {
            strokeDasharray: animate ? "2 0" : "0 2",
            transition: {
                duration: strikeThroughLineDuration/1000,
                ease: "easeInOut",
                delay: mergeAnimationLength/1000 - strikeThroughOffset/1000
            }
        },
        pinchout: {
            strokeDasharray: "2 0",
            strokeWidth: 0,
            transition: {
                duration: lineClosingDuration/1000,
                ease: "linear"
            }
        }
    }

    return (
        <>
        <Grid container
        style={{ backgroundColor: "#000" }}
        width={"100vw"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        >

            <div
            style={{ zIndex: 2, position: "relative" }}
            >
                <motion.a
                style={{ 
                    fontFamily: "Quicksand",
                    color: "#fff",
                }}
                variants={textVariants}
                initial={enter ? "startState2" : "startState"}
                animate={enter ? ["fadeout"] : ["fadein"]}
                >
                    KRISHNA MARRIAGE
                </motion.a>
            </div>

            <motion.svg
            height={height}
            viewBox="0 0 187.8 92.8"
            style={{ zIndex: 1, position: "absolute", maxWidth: "100%" }}
            initial={{
                scale: 1
            }}
            animate={{
                scale: enter ? 0 : 1
            }}
            transition={{
                duration: scaleDownDuration/1000,
                delay: lineClosingDuration/1000
            }}
            >

                <motion.path
                d="M 0 0 L -12 -10 L -4 -10 L 0 0 L 4 -10 L 12 -10 L 0 0 L 4 -10 L 12 -10 L 3.5 -2.92 L 8 -10 L 1.2 -3 L 0 -10 L -1.2 -3 L -8 -10 L -3.5 -2.92 L -12 -10 L 12 -10 L 6 -14 L 0 -10 L -6 -14 L -12 -10 L -8 -10 L -8 -12.665 L -6 -14 L 6 -14 L 8 -12.665 L 8 -10 L 0 -14 L -8 -10 L -4 -10 L -3.43 -12.28 L 0 -10 L 3.43 -12.28 L 4 -10"
                stroke={mainColors.primary}
                pathLength={2}
                strokeWidth={0.4}
                
                transform={`translate(138.8 21.2) rotate(25 0 0) scale(0.8)`}
                strokeLinecap="butt"
                strokeLinejoin="round"
                initial={{
                    opacity: 0
                }}
                animate={{
                    opacity: 1
                }}
                transition={{
                    delay: startDelay/1000 + mergeAnimationLength/1000 + strikeThroughLineDuration/1000 - strikeThroughOffset/1000 + textAnimationDuration/1000 - textOffset/1000 -diamondOffset/1000,
                    duration: 0.2
                }}
                />
                <motion.path
                d="M 0 0 L -12 -10 L -4 -10 L 0 0 L 4 -10 L 12 -10 L 0 0 L 4 -10 L 12 -10 L 3.5 -2.92 L 8 -10 L 1.2 -3 L 0 -10 L -1.2 -3 L -8 -10 L -3.5 -2.92 L -12 -10 L 12 -10 L 6 -14 L 0 -10 L -6 -14 L -12 -10 L -8 -10 L -8 -12.665 L -6 -14 L 6 -14 L 8 -12.665 L 8 -10 L 0 -14 L -8 -10 L -4 -10 L -3.43 -12.28 L 0 -10 L 3.43 -12.28 L 4 -10"
                stroke="#fff"
                pathLength={2}
                strokeWidth={0.4}
                
                transform={`translate(139 21) rotate(25 0 0) scale(0.8)`}
                strokeLinecap="butt"
                strokeLinejoin="round"
                initial={{
                    opacity: 0
                }}
                animate={{
                    opacity: 1
                }}
                transition={{
                    delay: startDelay/1000 + mergeAnimationLength/1000 + strikeThroughLineDuration/1000 - strikeThroughOffset/1000 + textAnimationDuration/1000 - textOffset/1000 -diamondOffset/1000,
                    duration: 0.2
                }}
                />
                <motion.path
                d="M -0.5 -0.5 C 0 -1 0 -2 0 -10 C 0 -1 0 -1 0.5 -0.5 C 1 0 2 0 10 0 C 2 0 1 0 0.5 0.5 C 0 1 0 2 0 10 C 0 2 0 1 -0.5 0.5 C -1 0 -2 0 -10 0 C -2 0 -1 0 -0.5 -0.5"
                // stroke="#fff"
                fill="#fff"
                pathLength={2}
                // strokeWidth={0.4}
                strokeLinecap="butt"
                strokeLinejoin="miter"
                initial={{
                    opacity: 0,
                    rotate: 45,
                    scale: 0.3,
                    x: 139.4,
                    y: 8.5
                }}
                animate={{
                    opacity: 1,
                    rotate: 255,
                    scale:1.5,
                    x: 139.4,
                    y: 8.5
                }}
                transition={{
                    delay: startDelay/1000 + mergeAnimationLength/1000 + strikeThroughLineDuration/1000 - strikeThroughOffset/1000 + textAnimationDuration/1000 - textOffset/1000 -diamondOffset/1000 + glintDelay/1000,
                    duration: glintDuration/1000,
                    ease: "circOut",
                    repeatType: "reverse",
                    repeat: 1
                }}
                />

                {
                    dots.map((props,index) => {
                        return (
                            <motion.circle
                            key={index}
                            cx={props.cx}
                            cy={props.cy}
                            r={props.radius}
                            fill={colors[props.color]}
                            fillOpacity={props.opacity}
                            initial={{
                                opacity: 0
                            }}
                            animate={{
                                opacity: 1
                            }}
                            transition={{
                                delay: startDelay/1000 + mergeAnimationLength/1000 + strikeThroughLineDuration/1000 - strikeThroughOffset/1000 + textAnimationDuration/1000 - textOffset/1000 -diamondOffset/1000 + glintDelay/1000 + props.delay,
                                duration: 2,
                                repeatType: "reverse",
                                repeat: Infinity
                            }}
                            />
                        )
                    })
                }

                

                { showLoops ?
                    loops.map((props, index) => {
                        const variants = {
                            startState: {
                                strokeDashoffset: props.offset,
                                scale: props.scale,
                                strokeWidth: props.width,
                                stroke: colors[props.color],
                                strokeDasharray: `0.8 1.2`,
                                x: props.translatex,
                                y: props.translatey,
                                opacity: props.opacity,
                            },
                            looping : {
                                strokeDashoffset: 2+props.offset,
                                transition: {
                                    duration: 7,
                                    repeat:Infinity,
                                    ease:"linear"
                                }
                            },
                            merging: {
                                scale : 1.5,
                                strokeWidth: 3,
                                stroke: "#fff",
                                strokeDasharray: `2 0`,
                                x: 0,
                                y: 0,
                                opacity: 1,
                                transition: {
                                    duration: mergeAnimationLength/1000,
                                    ease:"linear"
                                }
                            },
                            mergingShadow: {
                                scale : 1.4,
                                strokeWidth: 3,
                                stroke: mainColors.primary,
                                strokeDasharray: `2 0`,
                                x: 0,
                                y: 0,
                                opacity: 1,
                                transition: {
                                    duration: mergeAnimationLength/1000,
                                    ease:"linear"
                                }
                            },
                        }

                        return (
                            <>

                            <motion.path
                            key={index}
                            fill="transparent"
                            d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                            pathLength={2}
                            initial="startState"
                            animate={animate ? ["looping", (index === numOfLoops -1) ? "merging" : "mergingShadow"] : "looping"}
                            strokeLinecap="round"
                            strokeDasharray={`0.8 1.2`}
                            variants={variants}
                            />
                            </>
                        )
                    })
                    : (
                        <React.Fragment
                        >
                            <motion.path
                            fill="transparent"
                            d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                            pathLength={2}
                            initial={{
                                scale: 1.4
                            }}
                            strokeWidth={3}
                            stroke={mainColors.primary}
                            strokeDasharray={`2 0`}
                            />
                            <motion.path
                            fill="transparent"
                            d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                            pathLength={2}
                            initial={{
                                scale: 1.5
                            }}
                            strokeWidth={3}
                            stroke="#fff"
                            strokeDasharray={`2 0`}
                            />
                        </React.Fragment>
                    )
                }
                <motion.path
                d="M0,46.4L93.9,46.4L187.8,46.4"
                stroke="#000"
                pathLength={2}
                strokeWidth={20}
                // initial={{
                //     strokeDasharray: "0 2",
                // }}
                // animate={{
                //     strokeDasharray: animate ? "2 0" : "0 2"
                // }}
                // transition={{
                //     duration: strikeThroughLineDuration/1000,
                //     ease: "easeInOut",
                //     delay: mergeAnimationLength/1000 - strikeThroughOffset/1000
                // }}
                variants={lineVariants}
                initial="startState"
                animate={enter ? ["pinchout"] : ["drawin"] }
                />

            </motion.svg>

        </Grid>
        </>
    )
}

export default HeroAnimation