import TitleTextPageLayout from "../components/CombinedComponents/TitleTextPageLayout";

const introText = {
    title: "Terms and Conditions",
    introduction: "Please read the 'Terms and Conditions of Use' carefully before you open an account with Krishna Marriage. By becoming a user of Krishna Marriage, you accept and agree to be bound and abide by these 'Terms and Conditions of Use' and our Privacy Policy. These terms and conditions and the privacy policy form an agreement between Krishna Marriage (“Krishna Marriage”, “we”, “us”, “our”) and its clients (“you”, “clients”) when you subscribe to and use the Krishna Marriage service. Krishna Marriage reserves the right to amend these terms and conditions and the privacy policy from time to time as set out herein. Our headquarters are based in the UK but the services are offered globally. If you object to any provision of these terms and conditions and/or the privacy policy, including updates and amendments, you must immediately cancel your membership and cease accessing and using our services. "
}

const body = [
    {
        title: "Eligibility",
        text: "To become a user of Krishna Marriage you must be over 18 years of age, or over the legal age of majority in your country of residence, if different. You also must be inclined to spirituality, preferably the path of bhakti yoga. You must never have been or are not currently convicted of crimes of any nature. A gentle reminder that is not a casual dating website, but for seeking a life partner."
    },
    {
        title: "Terms of Use",
        text: `(i) There is a monthly subscription fee for registration with Krishna Marriage. This fee must be paid before you can access our services. You can cancel your subscription at any time. Your membership will end from the following month. 
        (ii) When registering as a member of Krishna Marriage, you must ensure that all information you provide is accurate and up-to-date. By creating an account, you grant Krishna Marriage the right to use (including to host, store, copy, reproduce, adapt, edit, modify or share) any information you include in your profile for the purposes of providing the service to you and other members.
        (iii) You are free to unsubscribe at any time.
        `
    },
    {
        title: "Your account",
        text: "You are responsible for maintaining the confidentiality of your login credentials you use to sign up and you are solely responsible for all activities that occur under those credentials. If you think someone has gained access to your account, please immediately on admin@krishnamarriage.com"
    },
    {
        title: "Termination",
        text: "The easiest way to terminate your account is to follow the instructions under 'Settings' in the Service. We may terminate your account at any time without notice if it believes that you have violated this Agreement. Upon such termination, you will not be entitled to any refund for purchases."
    },
    {
        title: "Our services",
        text: "Krishna Marriage provides registration and administration services for devotees of Krishna who are looking for a life partner, including a screening service by collecting one to two references per client. We provide a website and database in which users can enter their preferences to search for life partners. "
    },
    {
        title: "Limitation of Liability",
        text: "To the fullest extent permitted by applicable law, in no event will Krishna Marriage, its affiliates, employees, licensors or service providers be liable for any indirect, consequential, exemplary, incidental, special, punitive, or enhanced damages, including, without limitation, loss of profits, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from: (i) your access to or use of or inability to access or use the service, (ii) the conduct or content of other members or third parties on, though, or following use of the service; or (iii) unauthorized access, use or alteration of your content, even if Krishna Marriage has been advised of the possibility of such damages. Krishna Marriage does not make any representation or warranty as to the accuracy, reliability or completeness of any content contained on the website or transmitted by other clients on the system. Krishna Marriage takes no responsibility for any content that you or another member or third party posts, sends or receives through the service. Any material downloaded or otherwise obtained through the use of the service is accessed at your own discretion and risk. Krishna Marriage is not liable for verifying information about clients to other clients. We only pass on the information that is given to us by the clients themselves. Once a match has been made, it is the responsibility of the clients to gather sufficient information about each other before committing to each other. It is also the clients' responsibility as individuals to make a decision about whether to proceed to marriage. "
    },
    {
        title: "Your obligations",
        text: "You acknowledge that you use and access the Krishna Marriage matchmaking service at your own risk. Your membership is for personal use only and not for business or commercial purposes. You will treat e-mails and other messages received via or in relation to the Service confidentially and you will not disclose these messages to third parties without the consent of the sender. The same rules also apply to any names, phone and fax numbers, home addresses, e-mail addresses and/or URLs, or other personal information of other Krishna Marriage members. If you no longer wish to hear from us in relation to matches, or if you no longer want to be included as a potential partner in matches we propose to our other members (for example, because you have already found a partner), you must update your status in your profile to let us know immediately. It is important that our database remains accurate and up-to-date. If you do not let us know when you are no longer interested in finding a partner, this will have a detrimental effect on the quality of our service as provided to other members."
    },
    {
        title: "Disclaimer of Implied Warranties",
        text: "Except for the warranties expressly set forth herein, Krishna Marriage provides no warranty whatsoever with respect to the services, including warranty of merchantability, warranty of fitness for a particular purpose, warranty of title, or warranty against infringing of intellectual property rights of a third party, whether express or implied by law, course of dealing, course of performance, usage of trade or otherwise; nor does Krishna Marriage represent or warranty that the website or service will be uninterrupted, error free or secure, or that any defects or errors on the website or in the service will be corrected."
    },
    {
        title: "Third Party Services",
        text: "The Service may contain advertisements and promotions offered by third parties and links to other web sites or resources. Krishna Marriage is not responsible for the availability (or lack of availability) of such external websites or resources. If you choose to interact with the third parties made available through our service, such party's terms will govern their relationship with you. Krishna Marriage is not responsible or liable for such third parties' terms or actions."
    },
    {
        title: "Governing Law",
        text: "These Terms and Conditions of Use shall be governed by, and construed in accordance with, English Law"
    },
    {
        title: "Refund",
        text: "Generally, Krishna Marriage does not offer a refund for any of its services, subscriptions or in-app purchases or otherwise. There are no refunds or credits for partially used products. We may make an exception if a refund for a Krishna Marriage subscription is requested within 14 days of the transaction date, or if the laws applicable in your jurisdiction provide for refunds. Please contact admin@krishnamarriage.com if you would like to request a refund."
    }
]

const footerText = {
    title: "Thank you for reading",
    conclusion: "By using the Krishna Marriage service you agree and accept all these Terms and Conditions, as well as the Privacy Policy, Data Protection Policy, Cookie Policy and Community Guidelines"
}

const TermsAndConditions = () => {
    return (
        <TitleTextPageLayout titleTextArray={body} headerTextObj={introText} footerTextObj={footerText}/>
    )
}

export default TermsAndConditions
