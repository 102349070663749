import { Card, Divider, Grid, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { mainColors } from "../components/Theme/Colors/palette"
import LayoutContext from "../Layout/LayoutProvider"
import desktopCompleteProfile from "../img/desktopCompleteProfile-min.jpg"
import desktopHome from "../img/desktopHome-min.jpg"
import desktopMatching from "../img/desktopMatching-min.jpg"
import desktopSettings from "../img/desktopSettings-min.jpg"
import desktopFaq from "../img/desktopFaq-min.jpg"
import desktopUsefulLinks from "../img/desktopUsefulLinks-min.jpg"
import desktopTerms from "../img/desktopTerms-min.jpg"
import { motion } from "framer-motion"
import { useLocation } from "react-router-dom"
import { completeProfilePage, contactUsPage, faqPage, guidelinesPage, homePage, matchingPage, matchProfilePage, paymentFailedPage, paymentSuccessPage, settingsPage, termsAndConditionsPage, updateProfilePage, usefulLinksPage } from "./URLs"
import TopRightContent from "../Layout/TopRightContent"
import AvatarWithUsername from "../components/CombinedComponents/AvatarWithUsername"
import CPProgress from "../components/CombinedComponents/CPProgress"
import TextOverIcon from "../components/IndividualComponents/TextOverIcon"
import ResponsiveContext from "../ResponsiveDesign/ResponsiveProvider"
import KMNavBar from "../components/NavBar/KMNavBar"

const pics = {
    home: desktopHome,
    completeProfile: desktopCompleteProfile,
    matching: desktopMatching,
    settings: desktopSettings,
    faq: desktopFaq,
    usefulLinks: desktopUsefulLinks,
    terms: desktopTerms
}

const picsObject = {
    [homePage]: "home",
    [completeProfilePage]: "completeProfile",
    [matchingPage]: "matching",
    [matchProfilePage]: "matching",
    [updateProfilePage]: "completeProfile",
    [settingsPage]: "settings",
    [paymentFailedPage]: "settings",
    [paymentSuccessPage]: "settings",
    [faqPage]: "faq",
    [usefulLinksPage]: "usefulLinks",
    [termsAndConditionsPage]: "terms",
    [guidelinesPage]: "terms",
    [contactUsPage]: "terms"
}

const AuthLayout = () => {

    const { navPicWidth,
        mainBodyWidth,
        rightContentWidth,
        rightContentPaddingX,
        rightContentPaddingTop,
        cardHeight,
        rightContentInfoHeight,
        rightContentSpaceBetween,
        rightContentPaddingBottom,
        progress } = useContext(LayoutContext)

    const { isRemoveRightContent, isLaptop, isTablet } = useContext(ResponsiveContext);

    const navigate = useNavigate();

    const location = useLocation().pathname
    const [oldPic, setOldPic] = useState(location);
    const [newPic, setNewPic] = useState(location);
    const [startImgFadeOut, setStartImgFadeOut] = useState(false);
    const [startImgFadeIn, setStartImgFadeIn] = useState(false);
    const fadeInDuration = 400
    const fadeOutDuration = fadeInDuration
    
    const fullAnimation = fadeInDuration*2
    
    const [topRight, setTopRight] = useState("default");
    const topRightExceptions = {
        "default": <AvatarWithUsername/>,
        [completeProfilePage]: <CPProgress progress={progress}/>,
        [matchingPage]: <TextOverIcon/>,
        [matchProfilePage]: <TextOverIcon/>
    }

    useEffect(() => {
        setOldPic(location)
        if (Object.keys(topRightExceptions).includes(location)) {
            setTopRight(location)
        }
    }, [])

    useEffect(() => {
        if (location === oldPic) {
            return
        }

        if (Object.keys(topRightExceptions).includes(location)) {
            setTopRight(location)
        } else {
            setTopRight("default")
        }

        if (!picsObject[location]) {
            return
        }
        setNewPic(location)

        if (picsObject[oldPic] === picsObject[newPic]) {
            setOldPic(location)
            return
        }

        setStartImgFadeOut(true)
        setStartImgFadeIn(true)
        setTimeout(() => {
            setOldPic(location)
        }, fadeInDuration)
        setTimeout(() => {
            setStartImgFadeOut(false)
        }, fadeOutDuration)
        setTimeout(() => {
            setStartImgFadeIn(false)
        }, fullAnimation)

        return () => {
            clearTimeout(() => {
                setOldPic(location)
            }, fadeInDuration)
            clearTimeout(() => {
                setStartImgFadeOut(false)
            }, fadeOutDuration)
            clearTimeout(() => {
                setStartImgFadeIn(false)
            }, fullAnimation)
        }
    }, [location])

    return (
        <Grid container
        direction="row"
        style={{
            maxWidth:"100%",
            height: "100vh",
            width: "100vw"
        }}
        >
            {/* OLD PIC
            {isLaptop && <motion.div
            initial={{
                opacity: 1,
            }}
            animate={{
                opacity: startImgFadeOut ? 0 : 1,
            }}
            transition={{
                duration: fadeOutDuration/1000
            }}
            >
                <Grid item
                style={{
                    backgroundImage: `url(${picsObject[oldPic]})`,
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    backgroundAttachment: "fixed",
                    backgroundRepeat: "no-repeat",
                    height: "100%",
                    width: navPicWidth,
                    position: "fixed"
                }}
                />
            </motion.div>}

            {/* NEW PIC
            { isLaptop ? startImgFadeIn ?
                <motion.div
                initial={{
                    opacity: 0,
                }}
                animate={{
                    opacity: startImgFadeIn ? 1 : 0,
                }}
                transition={{
                    duration: fadeInDuration/1000
                }}
                >
                    <Grid item
                    style={{
                        backgroundImage: `url(${picsObject[newPic]})`,
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        backgroundAttachment: "fixed",
                        backgroundRepeat: "no-repeat",
                        height: "100%",
                        width: navPicWidth,
                        position: "fixed"
                    }}
                    />
                </motion.div>
                : <></> : <></> 
            } */}

            {isLaptop && Object.keys(pics).map(key => {
                return (
                    <motion.div
                    initial={{ opacity: (picsObject[oldPic] === key) ? 1 : 0 }}
                    animate={{ opacity: (picsObject[oldPic] === key) ?
                                            startImgFadeOut ? 0 : 1 :
                                            (picsObject[newPic] === key) ?
                                                startImgFadeIn ? 1 : 0 : 0}}
                    transition={{ duration: fadeInDuration/1000 }}>
                        <Grid item
                        style={{
                            backgroundImage: `url(${pics[key]})`,
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            backgroundAttachment: "fixed",
                            backgroundRepeat: "no-repeat",
                            height: "100%",
                            width: navPicWidth,
                            position: "fixed"
                        }}
                        />
                    </motion.div>
                )
            })}

            {/* NAVBAR */}
            <KMNavBar fadeInDuration={fadeInDuration}/>

            {/* MAIN BODY */}
            <Grid item
            xs={true}
            sx={{ ml: navPicWidth }}
            >
                <Outlet/>
            </Grid>

            {isRemoveRightContent && <Divider orientation="vertical"
            sx={{
                bgcolor: mainColors.primary,
                borderRightWidth:2
            }}
            style={{
                height: "100%",
                position:"fixed",
                left: `calc(${navPicWidth} + ${mainBodyWidth})`
            }}/>}

            {isRemoveRightContent && <TopRightContent>
                {topRightExceptions[topRight]}
            </TopRightContent>}

            {/* RIGHT CARD */}
            {isRemoveRightContent && <Grid item
            style={{
                width: rightContentWidth,
                position: "fixed",
                left: `calc(${navPicWidth} + ${mainBodyWidth})`,
                top: `${rightContentPaddingTop+rightContentInfoHeight+rightContentSpaceBetween}vh`
            }}
            sx={{
                px: rightContentPaddingX
            }}
            >
                <Card
                style={{
                    height: `${cardHeight}vh`
                }}
                elevation={0}
                />
            </Grid>}

            {/* FOOTER */}
            {isRemoveRightContent && <Grid item
            style={{
                left: `calc(${navPicWidth} + ${mainBodyWidth})`,
                position: "fixed",
                bottom: `${rightContentPaddingBottom}vh`,
                display: "flex",
                justifyContent: "center"
            }}
            sx={{
                px: rightContentPaddingX
            }}
            >
                <Typography style={{ cursor: "pointer" }}
                onClick={() => navigate(guidelinesPage)}>
                    Guidelines
                </Typography>
                <Typography sx={{ mx: 1 }}>
                    /
                </Typography>
                <Typography style={{ cursor: "pointer" }}
                onClick={() => navigate(faqPage)}>
                    FAQs
                </Typography>
                <Typography sx={{ mx: 1 }}>
                    /
                </Typography>
                <Typography style={{ cursor: "pointer" }}
                onClick={() => navigate(contactUsPage)}>
                    Contact Us
                </Typography>
                <Typography sx={{ mx: 1 }}>
                    /
                </Typography>
                <Typography style={{ cursor: "pointer" }}
                onClick={() => navigate(usefulLinksPage)}>
                    Useful Links
                </Typography>
            </Grid>}
        </Grid>
    )
}

export default AuthLayout