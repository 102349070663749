import { Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { registerPage } from "../../pages/URLs"
import { motion } from "framer-motion"
import ResponsiveContext from "../../ResponsiveDesign/ResponsiveProvider"
import { useContext } from "react"
import KMButton from "../IndividualComponents/KMButton"

const WelcomeMessage = () => {

    const navigate = useNavigate()
    const { resDesignLanding, isTablet, isLaptopLanding } = useContext(ResponsiveContext)

    const resDes = (laptop, tablet, mobile) => {
        return isLaptopLanding ? `${laptop}px`
               : isTablet ? `${tablet}px`
               : `${mobile}px`
    }

    return (
        <Grid container
        width={resDes(580, 500, 300)}
        sx={{ mx: resDesignLanding(80, 0, 0) }}
        >
            <Grid item xs={12}
            sx={{mb:"15px"}} 
            display="flex" 
            justifyContent="center"
            >
                <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                >
                    <Typography align="center" variant="secondaryTitleText">
                        Welcome to
                    </Typography>
                </motion.div>
            </Grid>

            <motion.div
            style={{ width: "100vw", maxWidth: "100%" }}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 1.5 }}
            viewport={{ once: true }}
            >
                    <Typography 
                    variant="titleText"
                    align="center"
                    style={{ display: "flex", justifyContent: "center" }}
                    sx={{lineHeight:1}}
                    >
                        Krishna Marriage
                    </Typography>
            </motion.div>

            <Grid item xs={12}
            sx={{mt:"20px", transform: `translateX(-${resDesignLanding(80, 50, 20)})`}}
            display="flex" 
            justifyContent="left">
                <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                animate={{ x: resDesignLanding(80, 50, 20) }}
                transition={{ delay: 1.2, duration: 1.5 }}
                viewport={{ once: true }}
                >
                <Typography variant="secondaryTitleText">
                    {"From moments of joy..."}
                </Typography>
                </motion.div>
            </Grid>

            <Grid item xs={12}
            sx={{mb:"30px", transform: `translateX(${resDesignLanding(80, 50, 20)})`}}
            display="flex" 
            justifyContent="right"
            >
                <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                animate={{ x: `-${resDesignLanding(80, 50, 20)}` }}
                transition={{ delay: 1.9, duration: 1.5 }}
                viewport={{ once: true }}
                >
                    <Typography variant="secondaryTitleText">
                        {"... to a lifetime of bliss"}
                    </Typography>
                </motion.div>
            </Grid>

            <Grid item xs={12}
            sx={{mb:"100px"}}
            display="flex" 
            justifyContent="center">
                <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ delay: 3.5, duration: 1.5 }}
                viewport={{ once: true }}
                >
                    <KMButton endArrow
                    text={"Start your journey now"}
                    variant={"accentTitle"}
                    onClick={()=>navigate(registerPage)}/>
                </motion.div>
            </Grid>

        </Grid>
    )
}

export default WelcomeMessage