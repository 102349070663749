import { TextField } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import StructureContext from "../../../StructureDecoder/context/StructureProvider";

const CPTextComp = (props) => {

    const { page, setPage, required, setRequired } = useContext(StructureContext)
    const path = props.path
    const thisPage = page(path[0])
    
    const handleFocus = () => {
        if (required.includes(path[2])) {
            const index = required.indexOf(path[2]);
            required.splice(index, 1);
            setRequired(required)
        }
    }

    return (
        <TextField sx={{mt:2, px:1}}
        value={thisPage[path[1]][path[2]].value}
        onChange={(e) => {
            thisPage[path[1]][path[2]].value = e.target.value
            setPage(path[0], thisPage)
        }}
        size="small"
        fullWidth
        margin="dense"
        error={required.includes(path[2])}
        onFocus={handleFocus}
        />
    )
}

export default CPTextComp