import TitleTextPageLayout from "../components/CombinedComponents/TitleTextPageLayout";

const introText = {
    title: "Privacy Policy",
    introduction: `Effective Date: 21-08-2023

    Welcome to our Krishna Marriage community! Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Krishna Marriage website (the "Website"). Please read this Privacy Policy carefully. By using the Website, you consent to our practices as described in this Privacy Policy.`
}

const policy = [
    {
        title: "Information We Collect",
        text: `We collect information you provide directly to us when you create an account, update your profile, or communicate with other members.
        
        We collect information about your interactions with the Website, including your matches, and browsing activities.`
    },
    {
        title: "How We Use Your Information",
        text: `We use the information you provide to create and manage your account, personalise your experience, and communicate with you.

        We may use your information to improve our services, monitor and analyse usage patterns, and address technical issues.`
    },
    {
        title: "Sharing of Your Information",
        text: `We may share your information with other members to facilitate connections and matches.
        
        We may share your information with third-party service providers for purposes such as data analysis, customer support, and marketing.`
    },
    {
        title: "Your Choices",
        text: `You can modify your account settings and privacy preferences to control the information you share.
        
        You can delete your account at any time, which will remove your profile and associated information.`
    },
    {
        title: "Security",
        text: `We implement security measures to protect your information, but no method of transmission over the internet is completely secure.`
    },
    {
        title: "Children's Privacy",
        text: "The Website is not intended for users under the age of 18, and we do not knowingly collect personal information from minors."
    },
    {
        title: "Changes to This Privacy Policy",
        text: "We may update this Privacy Policy from time to time. Any changes will be posted on this page."
    },
    {
        title: "Contact Us",
        text: "If you have questions or concerns about our Privacy Policy, please contact us at admin@krishnamarriage.com"
    }
]

const footerText = {
    title: "Thank you for reading",
    conclusion: "By using the Website, you agree to the terms of this Privacy Policy. If you do not agree with our practices, please do not use the Website. Thank you for being a part of our Krishna Marriage community!"
}

const DataProtection = () => {
    return (
        <TitleTextPageLayout titleTextArray={policy} headerTextObj={introText} footerTextObj={footerText}/>
    )
}

export default DataProtection
