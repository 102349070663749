import TitleTextPageLayout from "../components/CombinedComponents/TitleTextPageLayout";

const introText = {
    title: "Cookie Policy",
    introduction: `Effective Date: 21-08-2023

    Welcome to our Krishna Marriage community! This Cookie Policy explains how we use cookies, web beacons, and similar tracking technologies (collectively referred to as "cookies") on our Krishna Marriage website (the "Website"). By using the Website, you consent to the use of cookies as described in this Cookie Policy.`
}

const policy = [
    {
        title: "What are Cookies",
        text: `Cookies are small text files that are stored on your device when you visit a website.
        
        Cookies enable us to recognise your device, track your interactions with the Website, and gather information about your preferences.`
    },
    {
        title: "How We Use Cookies",
        text: `We use cookies to provide and improve our services, personalise your experience, and analyse usage patterns.

        Cookies help us understand how you use the Website and allow us to make improvements.`
    },
    {
        title: "Types of Cookies We Use",
        text: `Strictly Necessary Cookies: These cookies are essential for the functioning of the Website and cannot be turned off.
        
        Performance Cookies: These cookies collect information about how you use the Website and help us improve its performance.
        
        Functionality Cookies: These cookies remember your preferences and help enhance your user experience.`
    },
    {
        title: "Third-Party Cookies",
        text: `We may allow third-party service providers to set cookies on the Website for purposes such as analytics.
        
        Third-party cookies are subject to the privacy and cookie policies of those providers.`
    },
    {
        title: "Your Cookie Choices",
        text: `You can manage your cookie preferences by adjusting your browser settings.
        
        Please note that blocking or deleting cookies may impact your experience on the Website.`
    },
    {
        title: "Changes to This Policy",
        text: "We may update this Cookie Policy from time to time. Any changes will be posted on this page."
    },
    {
        title: "Contact Us",
        text: "If you have questions or concerns about our Cookie Policy, please contact us at admin@krishnamarriage.com."
    }
]

const footerText = {
    title: "Thank you for reading",
    conclusion: "By using the Website, you agree to the use of cookies as described in this Cookie Policy. If you do not agree with our practices, please do not use the Website. Thank you for being a part of our Krishna Marriage community!"
}

const CookiePolicy = () => {
    return (
        <TitleTextPageLayout titleTextArray={policy} headerTextObj={introText} footerTextObj={footerText}/>
    )
}

export default CookiePolicy
