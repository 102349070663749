import { Grid, Typography } from "@mui/material"
import MainBody from "../Layout/MainBody"
import { useContext } from "react"
import LayoutContext from "../Layout/LayoutProvider"

const UsefulLinks = () => {
    const { navBarWidth } = useContext(LayoutContext)

    return (
        <MainBody>
            <Grid container style={{ height: `calc(100vh - calc(${navBarWidth} + 20px))`, width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
            sx={{ px: "7vw" }}>
                <Grid container style={{ width: "100%", alignItems: "center" }}>
                    <Grid item xs={12}>
                        <Typography variant="h3">
                            Useful Links
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ mt: "20px" }}>
                                        <Typography variant="h4">
                                            Legal
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Terms
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Guidelines
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Privacy
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Data Protection
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Refund policy
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: "20px" }}>
                                        <Typography variant="h4">
                                            Other
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            FAQs
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ mt: "20px" }}>
                                        <Typography variant="h4">
                                            Get in touch
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Admin requests
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Bug report
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Report a profile
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: "20px" }}>
                                        <Typography variant="h4">
                                            Socials
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </MainBody>
    )
}

export default UsefulLinks