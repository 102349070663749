import { Button, Typography } from "@mui/material"
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import { useContext } from "react";
import ResponsiveContext from "../../ResponsiveDesign/ResponsiveProvider";
import { mainColors } from "../Theme/Colors/palette";
import LoadingComp from "./LoadingComp";

const buttonVariants = {
    accent: {
        buttonProps: {
            color: "accent",
            variant: "contained",
            sx: { px: 2.5, mx: 2.5 }
        },
        typographyProps: {
            variant: "body1",
            fontFamily: "Quicksand"
        },
        loadingTypoProps: {
            color: "rgba(0, 0, 0, 0.3)"
        },
        loadingProps: {
            mainColor: mainColors.accent,
            secColor: mainColors.lightAccent
        }
    },
    nonAuthNavBar: {
        buttonProps: {
            color: "textColor",
            variant: "text",
            sx: { px: 2.5, mx: 2.5 },
            disableRipple: true,
            style: { backgroundColor: "transparent" }
        },
        typographyProps: {
            variant: "body1",
            fontFamily: "Quicksand"
        },
        loadingTypoProps: {
            color: "rgba(0, 0, 0, 0.3)"
        },
        loadingProps: {
            mainColor: mainColors.textColor,
            secColor: mainColors.grey
        }
    },
    accentTitle: {
        buttonProps: {
            color: "accent",
            variant: "contained",
            sx: { px: 2.5, mx: 2.5 }
        },
        typographyProps: {
            variant: "buttonTitleText"
        },
        loadingTypoProps: {
            color: "rgba(0, 0, 0, 0.3)"
        },
        loadingProps: {
            mainColor: mainColors.accent,
            secColor: mainColors.lightAccent
        }
    },
    impTitle: {
        buttonProps: {
            color: "important",
            variant: "contained",
            sx: { px: 2.5, mx: 2.5 }
        },
        typographyProps: {
            variant: "buttonTitleText"
        },
        loadingTypoProps: {
            color: "rgba(0, 0, 0, 0.3)"
        },
        loadingProps: {
            mainColor: mainColors.important,
            secColor: mainColors.pink
        }
    },
}

const KMButton = ({
    buttonProps,
    typographyProps,
    variant,
    text,
    onClick,
    upperCase,
    bold,
    endArrow,
    startArrow,
    loading
}) => {
    const { resDesignLanding } = useContext(ResponsiveContext)
    const arrowProps = {style: { fontSize: resDesignLanding(25, 20, 15) }}
    const sideIcon = {
        endIcon: endArrow ? <EastIcon {...arrowProps}/> : <></>,
        startIcon: startArrow ? <WestIcon {...arrowProps}/> : <></>
    }

    const variantProps = buttonVariants[variant] || buttonVariants.accent

    const isLoadingRight = loading && startArrow
    const isLoadingLeft = loading && !startArrow
    const loadingProps = {width: resDesignLanding(40, 35, 25), ...variantProps.loadingProps}
    return (
        <Button {...{ ...variantProps.buttonProps, ...buttonProps, onClick, ...sideIcon, disabled: loading }}>
            { isLoadingLeft && <LoadingComp {...loadingProps}/> }
            <Typography {...{ ...variantProps.typographyProps,
                ...typographyProps,
                style: { fontWeight: bold ? "bolder" : "normal" },
                sx: { mr: isLoadingRight ? "10px" : "", ml: isLoadingLeft ? "10px" : "" },
                color: loading ? variantProps.loadingTypoProps.color : ""}}>
                { upperCase ? text.toUpperCase() : text }
            </Typography>
            { isLoadingRight && <LoadingComp {...loadingProps}/> }
        </Button>
    )
}

export default KMButton