import { Grid } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FullMatch from "../components/CombinedComponents/FullMatch";
import MatchingTiles from "../components/CombinedComponents/MatchingTiles";
import LoadingComp from "../components/IndividualComponents/LoadingComp";
import useAccessTokenInfo from "../hooks/useAccessTokenInfo";
import useFullMatching from "../hooks/useFullMatching";
import MainBody from "../Layout/MainBody";
import RightContent from "../Layout/RightContent";

const MatchProfile = () => {

    const navigate = useNavigate()

    const user = useAccessTokenInfo("user")
    const quickRes = useFullMatching(user)
    
    const [res, setRes] = useState(null);
    const [accepted, setAccepted] = useState(null);
    const [rejected, setRejected] = useState(null);

    const acceptedFunc = () => {
        setAccepted(true)
    }
    const rejectedFunc = () => {
        setRejected(true)
    }

    setTimeout(() => {
        setRes(quickRes)
    }, 1000) 

    if (!res) {
        return (
            <MainBody>
                <Grid container
                style={{
                    height: "100vh",
                    width: "100%",
                }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                >
                    <LoadingComp width="15vw" />
                </Grid>
            </MainBody>
        )
    }


    return (
        <>
            <MainBody>
                <Grid item
                style={{height: "auto"}}
                sx={{px:"1.5vw"}}
                display="flex"
                alignItems="start"
                >
                    <FullMatch matchingData={res.data}
                    accepted={acceptedFunc}
                    rejected={rejectedFunc}
                    />   
                </Grid>
            </MainBody>


            <RightContent>
                {accepted || rejected ? <></> : <MatchingTiles />}
            </RightContent>
        </>
    )
        
}
    
export default MatchProfile