import { useContext, useState } from "react";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import DecodedStructure from "../components/ProfileDecoder/DecodedStructure"
import PageButtons from "../components/CombinedComponents/PageButtons";
import { purple } from "@mui/material/colors";
import DoneIcon from '@mui/icons-material/Done';
import { StructureProvider } from "../StructureDecoder/context/StructureProvider";
import { numOfPages } from "../components/ProfileDecoder/structure";
import LayoutContext from "../Layout/LayoutProvider";
import MainBody from "../Layout/MainBody";
import RightContent from "../Layout/RightContent";
import { mainColors } from "../components/Theme/Colors/palette";

const cardContent = [
    [
        "Hema & Anish (UK)",
        "When I joined the Krishna Marriage project, the matchmaker who I contacted was very helpful, understanding and personal. The matchmakers at Krishna Marriage tried their best to fulfil my needs and were very approachable. We are both very grateful to the matchmakers at Krishna Marriage. Thank you very much for everything!"
    ],
    [
        "When completing these sections you might want to think about your hobbies, for example sports, artistic projects or cooking. Give an all around picture of your personality as this will help people to understand who you are. Remember to be authentic.",
        "If you provide us with a reference at the end of completing your profile, they will be sent your “About You” section to verify your words"
    ],
    [
        "When completing the spiritual part of your profile, honesty is the best policy. Spiritual life is dynamic and gradually progressive, and therefore we have given you a level of openness in your options. You can also add more specific details on your plans to progress in the free text box.",
        "If you provide us with a reference at the end of completing your profile, they will be sent your “???” sections to verify your claims"
    ],
    [
        "Guru Bhakti Devi Dasi & Saranga Thakur Das (UK)",
        "We would like to thank Krishna Marriage for introducing us to each other. We feel blessed to raise a Krishna conscious family. We are just celebrating our 4th wedding anniversary and our wonderful daughter Palika will have her 3rd birthday soon."
    ],
    [
        "It is important to add references who are well recognised members of ISKCON - this might be a mentor or a service leader or a member of the temple leadership team. We will reach out to your references to verify your profile details - please see our FAQs to understand what we share with your reference. Once this process is complete, your profile will be marked with a verified ice cream letting other users know your profile is authentic."
    ]
]

const pageNames = {
    "1": "Personal",
    "2": "About You",
    "3": "Spiritual",
    "4": "Your Partner",
    "5": "References"
}
const pageNumbers = [...Array(numOfPages).keys()]

const CompleteProfile = () => {

    const { setProgress } = useContext(LayoutContext)

    const [index, setIndex] = useState(0);
    const updateIndex = (index) => {
        setIndex(index)
    }

    return (
        <Grid container direction="row" style={{width:"100%"}}
        position="relative">

            <MainBody>
                <Grid item
                style={{height: "auto"}}
                sx={{px:"1.5vw"}}
                >
                    <Box sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        background: mainColors.background,
                        top: 0
                    }}
                    position="fixed"
                    zIndex={100}
                    width="47vw"
                    display="flex"
                    justifyContent="center"
                    >

                        <Tabs value={index}
                        TabIndicatorProps={{ style: { background: purple[500] }}}
                        width="50vw"
                        >
                            {pageNumbers.map(number => {
                                
                                const isPage = ((number) === index)
                                const isBehind = (number < index)
                                
                                return (
                                    <Tab label={
                                        <Typography variant="h6"
                                        sx={{ px: isPage ? "1.5vw" : "0.2vw" }}
                                        display="flex"
                                        alignItems="center"
                                        >
                                            {pageNames[number + 1]}
                                            { isBehind ? <DoneIcon sx={{ml:"0.2vw"}}/> : <></>}
                                        </Typography>
                                    }
                                    textColor={mainColors.accent}
                                    disabled
                                    sx={{ "&.Mui-disabled": { color: isBehind ? mainColors.green : purple[500], fontSize: "2vh"  }, height: "6.5vh" }}
                                    key={number}
                                    />
                                    )
                                })}
                        </Tabs>
                    </Box>
                    
                    <StructureProvider>
                        <DecodedStructure
                        index={index}
                        />

                        <PageButtons
                        updateIndex={updateIndex}
                        index={index}
                        setProgress={setProgress}
                        />
                    </StructureProvider>

                </Grid>
            </MainBody>

            <RightContent>
                <Grid container
                style={{
                    width: "100%",
                    height: "100%",
                    wordWrap: "break-word",
                    overflow: "auto"
                }}
                sx={{
                    px: "1vw",
                    my: "1vh"
                }}
                >
                    <Grid container>
                    {
                        cardContent[index].map((text, index) => {
                            return (
                                <Grid item xs={12} key={index}>
                                    <Typography variant="h6">
                                        {text}
                                    </Typography>
                                </Grid>
                            )
                        })
                    }
                    </Grid>
                </Grid>
            </RightContent>

        </Grid>
    )
        
}
    
export default CompleteProfile