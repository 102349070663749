import { Grid, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MatchingTiles from "../components/CombinedComponents/MatchingTiles";
import PreferencesOverlay from "../components/CombinedComponents/PreferencesOverlay";
import SummarizedMatch from "../components/CombinedComponents/SummarizedMatch";
import LoadingComp from "../components/IndividualComponents/LoadingComp";
import useAccessTokenInfo from "../hooks/useAccessTokenInfo";
import useMatching from "../hooks/useMatching";
import usePrefsDone from "../hooks/usePrefsDone";
import useReadyForMatches from "../hooks/useReadyForMatches";
import { completeProfilePage } from "./URLs";
import MainBody from "../Layout/MainBody";
import RightContent from "../Layout/RightContent";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const Matching = () => {

    const navigate = useNavigate()
    var noneFound = false
    var onCooldown = false

    const info = useAccessTokenInfo()
    const user = info?.user || ""
    
    const [res, setRes] = useState(null);
    const [prefsDone, setPrefsDone] = useState(null);
    const [readyForMatching, setReadyForMatching] = useState(null);

    useEffect(() => {
        if (!user) {
            navigate(completeProfilePage)
            return
        }
    },[])

    const quickRes = useMatching(user)
    const quickPrefsDone = usePrefsDone(user)
    const quickreadyForMatching = useReadyForMatches(user)

    // const quickRes = null
    setTimeout(() => {
        setRes(quickRes)
    }, 1000)
    setTimeout(() => {
        setPrefsDone(quickPrefsDone)
    }, 1000)
    setTimeout(() => {
        setReadyForMatching(quickreadyForMatching)
    }, 1000)

    const [enter, setEnter] = useState(null);

    const changeEnter = () => {
        setEnter(true)
    }

    if (!enter) {
        if (!readyForMatching) {
            return (
                <MainBody>
                    <Grid container
                    style={{
                        height: "100vh",
                        width: "100%",
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    >
                        <LoadingComp width="15vw" />
                    </Grid>
                </MainBody>
            )
        } else if (readyForMatching.data === "N") {
            navigate(completeProfilePage)
        }
    
        if (!prefsDone) {
            return (
                <MainBody>
                    <Grid container
                    style={{
                        height: "100vh",
                        width: "100%",
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    >
                        <LoadingComp width="15vw" />
                    </Grid>
                </MainBody>
            )
        } else if (prefsDone.data === "N") {
            return (
                <Modal
                open={true}
                style={{ overflow: "scroll" }}
                className="no-scroll"
                >
                    <PreferencesOverlay user={user} enterfunc={changeEnter}/>
                </Modal>
            )
        }
    
        
        if (readyForMatching.data === "Y" && prefsDone.data === "Y") {
            setEnter(true)
        }
    }
    
    if (enter) {
        
        if (!res) {
            return (
                <MainBody>
                    <Grid container
                    style={{
                        height: "100vh",
                        width: "100%",
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    >
                        <LoadingComp width="15vw" />
                    </Grid>
                </MainBody>
            )
        }

        if (res.data === "None" || res.data === "Cooldown") {
            noneFound = true
            if (res.data === "Cooldown") {
                onCooldown = true
            }
        }

        return (
            <Grid container direction="row" style={{width:"100%"}}
            >
                <MainBody>
                    <Grid item
                    style={{height: "auto"}}
                    sx={{px:"1.5vw"}}
                    display="flex"
                    alignItems="start"
                    >
                        { noneFound 
                            ? onCooldown ? (
                                <Grid container
                                sx={{px:"6vw"}}
                                >
                                    <Grid item xs={12}
                                    display="flex"
                                    justifyContent="center"
                                    sx={{mt:"12vh"}}
                                    >
                                        <HourglassEmptyIcon
                                        style={{
                                            fontSize: "20vh"
                                        }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                        style={{
                                            fontSize: "6vh"
                                        }}
                                        sx={{mb: "4vh"}}
                                        align="center"
                                        >
                                            Please come back later
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                        style={{
                                            fontSize: "3vh"
                                        }}
                                        align="center"
                                        >
                                            We have a cooldown of 3 days between any profiles you see. We do this to ensure everyone's profile is being seriously considered.
                                        </Typography>
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <Typography
                                        style={{
                                            fontSize: "3vh"
                                        }}
                                        sx={{mt:"4vh"}}
                                        align="center"
                                        >
                                            
                                        </Typography>
                                    </Grid> */}
                                    <Grid item xs={12}>
                                        <Typography
                                        style={{
                                            fontSize: "3vh"
                                        }}
                                        sx={{mt:"4vh"}}
                                        align="center"
                                        >
                                            Don't worry, once we present a profile to you, you can think about it for as long as you want! If 3 days have passed since we showed you the profile, we will instantly show you another after your decision.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                        style={{
                                            fontSize: "3vh"
                                        }}
                                        align="center"
                                        >
                                           
                                        </Typography>
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <Typography
                                        style={{
                                            fontSize: "4vh"
                                        }}
                                        align="center"
                                        >
                                            Tell your single friends! Tell your crush!
                                        </Typography>
                                    </Grid> */}
                                </Grid>
                            ) : (
                                <Grid container
                                sx={{px:"6vw"}}
                                >
                                    <Grid item xs={12}
                                    display="flex"
                                    justifyContent="center"
                                    sx={{mt:"8vh"}}
                                    >
                                        <FormatListBulletedIcon
                                        style={{
                                            fontSize: "20vh"
                                        }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                        style={{
                                            fontSize: "6vh"
                                        }}
                                        align="center"
                                        >
                                            We couldn't find a match
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                        style={{
                                            fontSize: "3vh"
                                        }}
                                        align="center"
                                        >
                                            Unfortunately our search hasn't returned any results on this occasion. 
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                        style={{
                                            fontSize: "3vh"
                                        }}
                                        sx={{mt:"4vh"}}
                                        align="center"
                                        >
                                            We are as good as our database!
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                        style={{
                                            fontSize: "3vh"
                                        }}
                                        align="center"
                                        >
                                            We need you to spread the word about Krishna Marriage. The more people we have, the more success you will have.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                        style={{
                                            fontSize: "4vh"
                                        }}
                                        align="center"
                                        >
                                            Tell your single friends! Tell your crush!
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )
                            : (
                                <SummarizedMatch matchingData={res.data}/>
                                
                                )
                            }
                    </Grid>
                </MainBody>
    
                <RightContent>
                    <MatchingTiles />
                </RightContent>
    
            </Grid>
        )
    }
        
}
    
export default Matching