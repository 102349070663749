import TitleTextPageLayout from "../components/CombinedComponents/TitleTextPageLayout";

const introText = {
    title: "FAQs",
    introduction: ""
}

// {
//     title: "",
//     text: ""
// },

const FAQ = [
    {
        title: "What is Krishna Marriage?",
        text: "Krishna Marriage is an offering to help especially the devotee community to be established in grihastha ashram. Grihastha ashram is the nucleus of our communities as they support all other ashrams. Krishna Marriage allows devotees to connect to each other who might have similar goals."
    },
    {
        title: "Who can sign up to Krishna Marriage?",
        text: "Krishna Marriage is available for anyone above the age of 18, looking for a long term partnership to serve Krishna together. "
    },
    {
        title: "What is the history of Krishna Marriage",
        text: "Krishna Marriage started as an offering by devotees for devotees. HG Visambhar Prabhu and HG Jaganathesvari Mataji (ACBSP) have served the devotee community with this matrimonial offering since the early 2000s, where it started as a paper based system. We thank them for their continued effort and guidence to help devotees to find meaningful partnerships."
    },
    {
        title: "Is Krishna Marriage safe for me to use",
        text: "The Krishna Marriage team has added checks at different stages on signing up and matching. This starts from verifying your email to providing a reference. Your data is protected by modern cloud based protection systems that will prevent various different types of attackers. You can also protect yourself on the platform by being careful what information you share in your profile, making sure that personally identifiable information does not become compromised - please read our community guidelines for more information on how to stay safe online."
    },
    {
        title: "What profile details are sent to my reference?",
        text: "From your personal information your name and profile picture are sent to your reference. From the About You section, your long description is sent to your reference. Finally, from the spiritual section of your profile, we send your reference the number of rounds you chant and number of regulative principles you follow."
    },
    {
        title: "Why are references important?",
        text: "Krishna Marriage is determined to protect the candidates to the best of our ability. References are one of the ways to verify the information that you have provided to us and are displaying on your profile. Once you have been verified you will receive a special badge notifying other users that your profile has been checked."
    },
    {
        title: "My reference hasn't received an email",
        text: "Sometimes our emails get sent to the Spam or Promotions folder in your email server, please ask your references to check these areas and then add our email address (admin@krishnamarriage.com) as a contact. Inside the email, your reference will be prompted to complete a form on our website. This will then be verified by our admin team."
    },
    {
        title: "Why do we use subscription model",
        text: "In the past, we have found that users can lie dormant in our database for a long time, and our team does not know the status of such a user. They could have been married! We have implemented a subscription model so that users are regularly encouraged to be active on their profile. This model gives you more options, and allows you to better control your commitment to Krishna Marriage. Our subscriptions are available on the subscription page."
    },
    {
        title: "Why do we limit potential matches",
        text: "One of the core values of Krishna Marriage is thoughtful decisions. We believe that you should take the time to read a candidate's profile and understand if they will be a good fit for you. It's not about the instant judgement based on photos, or immediate rejection because of spiritual differences. With this in mind, you are limited with how many potential matches you are presented with and how many searches you can execute on our database. You can look back at the profiles that you were matched against and therefore make a thoughtful decision on whether to pursue it."
    },
    {
        title: "How does our matching process work",
        text: "Your partner preferences drive the candidates you attract. Primarily, we search for candidates that match your age and height preferences. If this search provides plentiful results, then we filter out those who have the best matches of relocation, children and living situations. If this search returns no results, we make this search fuzzier - we expand the age and height range that you provided. We are still growing our user-base and database, so the more you encourage your single friends to join our platform, the better experience everyone will have."
    },
    {
        title: "My account has been deactivated",
        text: `Your account may have been deactivated for a number of reasons:
        (1) You may have been reported by another user for inappropriate content or behaviour,
        (2) Our content filters may have flagged your account for inappropriate content,
        (3) You have deactivated your account by changing your status.
        If your account has been reported for inappropriate content or behaviour then your account will be banned for 30 days. This is a warning. Please ensure that you follow our Community Guidelines when creating a profile and completing your information. If your account is flagged again, and upon review, our team finds inappropriate content or offensive behaviour, your account will be banned indefinitely.
        Your account may have been deactivated by a status change. This is usually initiated by the user. If this has been a mistake or you did not deactivate your account, you can contact our admin team on admin@krishnamarriage.com.
        `
    },
    // {
    //     title: "I cannot access my account anymore",
    //     text: "Answer"
    // },
    // {
    //     title: "My search is not working",
    //     text: "Answer"
    // },
    {
        title: "I am not receiving any matches",
        text: "The success of any online matching platform primarily depends on the size of its user base. Krishna Marriage is a well known brand, and recognised in the devotional community as the place to be when looking for a match within our community. Since our refresh, we need to regrow our user base, therefore we invite you all to share your experience of Krishna Marriage with your friends and via social media. We have purposefully designed the new Krishna Marriage to empower you. The more users we have, the more successful matches you have."
    },
    {
        title: "Do you provide any counseling or astrology help",
        text: "Krishna Marriage is a platform to find other individuals looking for a long term partnership/marriage. We do not provide counseling or astrological help."
    },
    {
        title: "Can I get a refund",
        text: "We are sorry to hear that our services do not fulfill your purpose. Generally, Krishna Marriage does not offer a refund for any of its services, subscriptions or in-app purchases or otherwise. There are no refunds or credits for partially used products. If you have accidentally made a duplicate payment, we will refund it. "
    },
]

const footerText = {
    title: "Thank you for reading",
    conclusion: "If your question has not been answered on this page, please contact us directly at admin@krishnamarriage.com"
}

const Faq = () => {
    return (
        <TitleTextPageLayout titleTextArray={FAQ} headerTextObj={introText} footerTextObj={footerText}/>
    )
}

export default Faq
