import { createTheme, emphasize } from "@mui/material/styles"
import "@fontsource/roboto"
import { colorPalette, mainColors } from './Colors/palette'
import OvoRegularTTF from "./Fonts/Ovo-Regular.ttf"
import Quicksand from "./Fonts/Quicksand-VariableFont_wght.ttf"
import { purple, red } from '@mui/material/colors'
import { useLocation } from "react-router-dom"

const laptopBreak = 1280
const tabletBreak = 600

let theme = createTheme({})

const resTheme = (laptop, tablet, mobile) => {
    return {
        [theme.breakpoints.up(laptopBreak)]: {
            fontSize: `${laptop}px`
        },
        [theme.breakpoints.between(tabletBreak, laptopBreak)]: {
            fontSize: `${tablet}px`
        },
        [theme.breakpoints.down(tabletBreak)]: {
            fontSize: `${mobile}px`
        },
    }
}

theme = createTheme(theme, {
    palette: colorPalette,

    typography:{
        button: {
            fontWeight:"bold",
            textTransform: "none"
        },
        titleText: {
            ...resTheme(160, 110, 80),
            fontFamily: "serif",
            color: mainColors.background
        },
        secondaryTitleText: {
            ...resTheme(40, 30, 22),
            fontFamily: "Ovo",
            color: mainColors.background
        },
        buttonTitleText: {
            ...resTheme(20, 20, 15),
            fontFamily: "Ovo",
            color: mainColors.accentText
        },
        errorText: {
            color: red[500],
            fontFamily: "Ovo",
            fontSize: "2.6vh",
        },
        h1: {
            fontFamily: "serif",
            // fontSize: "12vh"
            ...resTheme(100, 80, 50)
        },
        h2: {
            fontFamily: "serif",
            // fontSize: "7vh"
            ...resTheme(70, 60, 40)
        },
        h3: {
            fontFamily: "serif",
            // fontSize: "5vh"
            ...resTheme(50, 40, 30)
        },
        h4: {
            fontFamily: "Ovo",
            // fontSize: "3.5vh"
            ...resTheme(32, 28, 23)
        },
        h5: {
            fontFamily: "Ovo",
            // fontSize: "2.5vh"
            ...resTheme(28, 25, 22)
        },
        h6: {
            fontFamily: "Ovo",
            // fontSize: "2vh"
            ...resTheme(18, 17, 15)
        },
        body1: {
            fontFamily: "Ovo",
            fontSize: "14px"
            // fontSize: "1.5vh"
        }
    },

    components: {

        MuiCard: {
            styleOverrides: {

                root: {
                    border:"2px solid",
                    borderWidth: 3,
                    borderRadius: 15,
                    borderColor: mainColors.primary
                },

            }
        },


        //Button
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 28
                },

                outlined: {
                    borderWidth: 3,
                    borderColor: mainColors.textColor
                }
            },


            variants: [
                {
                    props: {variant: "checkedPill"},
                    style: {
                        border: "2px solid",
                        color: mainColors.accent,
                        borderColor: mainColors.accent,
                        background: mainColors.primary,
                    },
                },
                {
                    props: {variant: "backCP"},
                    style: {
                        border: "0px solid",
                        color: mainColors.textColor,
                        borderColor: mainColors.textColor,
                        background: "transparent",
                    },
                },
                {
                    props: {variant: "outlinedBlack"},
                    style: {
                        border: "2px solid",
                        color: mainColors.textColor,
                        borderColor: mainColors.textColor,
                        background: "transparent",
                    },
                },
                {
                    props: {variant: "filledBlack"},
                    style: {
                        border: "2px solid",
                        color: mainColors.textColor,
                        borderColor: mainColors.textColor,
                        background: mainColors.textColor,
                    }
                }
            ]
        },


        //Textfield
        MuiOutlinedInput: {
            styleOverrides: {

                root: {
                    borderRadius: 20,
                    background: mainColors.textFieldBackground,
                    borderWidth: '3px',

                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: mainColors.primary,
                        borderWidth: '3px'
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black"
                    }
                },
                
                notchedOutline: {
                    borderWidth: 3,
                    borderColor: mainColors.primary,
                }
            }
        },


        MuiCssBaseline: {
            styleOverrides: {
              "@font-face": [
                {
                fontFamily: "Ovo",
                src: `url(${OvoRegularTTF}) format("truetype")`
                },
                {
                fontFamily: "Quicksand",
                src: `url(${Quicksand}) format("truetype")`
              }],

              body: {
                scrollbarColor: `${mainColors.primary} ${mainColors.background}`,
                "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                  backgroundColor: mainColors.background,
                },
                "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                  backgroundColor: mainColors.primary,
                },
                "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                  backgroundColor: mainColors.primary,
                },
                "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                  backgroundColor: mainColors.primary,
                },
                "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: mainColors.primary,
                },
                "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                  backgroundColor: mainColors.primary,
                },
              },
            }
        },

        MuiRadio: {
            styleOverrides: {
                root: {
                    color: purple[500],
                }
            }
        },

        MuiChip: {
            styleOverrides: {
                root: {
                    color: mainColors.textColor,
                    borderWidth: 3,
                    borderColor: mainColors.primary,
                },
                clickable: {
                    cursor: "pointer",
                    '&:hover, &:focus': {
                        backgroundColor: emphasize(mainColors.primary, 0),
                      },
                    '&:active': {
                    backgroundColor: emphasize(mainColors.primary, 0.08),
                    },
                },
                label: {
                    fontSize: "15px"
                }

                
            }
        },


        // MuiDivider: {
        //     styleOverrides: {
        //         root: {
        //             color: mainColors.accent
        //         }
        //     }
        // }

        MuiMenu: {
            styleOverrides: {
                root: {
                    maxHeight:"25vh"
                }
            }
        }

    }
})

export default theme