import { Autocomplete, TextField } from "@mui/material"
import axios from "axios"
import { useContext, useEffect, useState } from "react"
import StructureContext from "../../../StructureDecoder/context/StructureProvider";
import { languagesAll } from "countries-list";

const languagesList = Object.keys(languagesAll).map(key => {
    return languagesAll[key].name
})
const CPAutocompleteLanguage = (props) => {
    const [countries, setCountries] = useState(languagesList);

    const { page, setPage, required, setRequired } = useContext(StructureContext)
    const path = props.path
    const thisPage = page(path[0])

    const handleFocus = () => {
        if (required.includes(path[2])) {
            const index = required.indexOf(path[2]);
            required.splice(index, 1);
            setRequired(required)
        }
    }

    return (
        <Autocomplete
        fullWidth
        options={countries}
        value={thisPage[path[1]][path[2]].value}
        onChange={(e, newval) => {
            thisPage[path[1]][path[2]].value = newval
            setPage(path[0], thisPage)
        }}
        onFocus={handleFocus}
        renderInput={(params) => <TextField
            {...params}
            sx={{mt:2, px:1}}
            margin="dense"
            size="small"
            value={thisPage[path[1]][path[2]].value}
            />
        }
        />
    )
}

export default CPAutocompleteLanguage