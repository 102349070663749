import { useNavigate } from "react-router-dom"
import { loginPage, registerPage } from "./URLs"
import { Grid } from "@mui/material"
import WelcomeMessage from "../components/LandingComps/WelcomeMessage"
import desktopLanding from "../img/desktopLanding-min.jpg"
import desktopLandingServices from "../img/desktopLandingServices-min.jpg"
import OurServices from "../components/LandingComps/OurServices"
import ServiceTiles from "../components/LandingComps/ServiceTiles"
import { mainColors } from "../components/Theme/Colors/palette"
import Testimonials from "../components/LandingComps/Testimonials"
import Footer from "../components/CombinedComponents/Footer"
import { useRef } from "react"
import CookieConsent from "react-cookie-consent"
import { useContext } from "react";
import ResponsiveContext from "../ResponsiveDesign/ResponsiveProvider"
import NonAuthNavBar from "../components/NavBar/NonAuthNavBar"
import KMButton from "../components/IndividualComponents/KMButton"

const Landing = () => {
    const { isTabletLanding, isLaptopLanding, resDesignLanding } = useContext(ResponsiveContext)

    const ref = useRef(null)
    const missionRef = useRef(null)
    const servicesRef = useRef(null)
    const testimonialsRef = useRef(null)

    const links = [
        { name: "Our Services", handleClick: () => servicesRef.current.scrollIntoView() },
        { name: "Testimonials", handleClick: () => testimonialsRef.current.scrollIntoView() }
    ]

    return (
        <>
        <CookieConsent>
            This website only uses essential cookies
        </CookieConsent>

        <Grid container ref={ref}>
            <Grid item xs={12} height="auto"
            minHeight="100vh">
                <Grid container
                style={{backgroundImage: `url(${desktopLanding})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundAttachment: "fixed",
                minHeight: "100vh",
                minWidth: "100%",
                }}
                >
                    <Grid item xs={12}>
                        <NonAuthNavBar links={links}/>
                    </Grid>
                    <Grid item xs={12}
                    style={{background: "rgba(0,0,0,0.5)",
                    height: `calc(100vh - ${resDesignLanding(64, 64, 60)})`,
                    display: "flex",
                    alignItems: "center"
                    }}
                    >
                        {
                            isLaptopLanding ?
                            <Grid container
                            direction="row-reverse"
                            width="100%"
                            height="100%"
                            display={ "flex"}
                            justifyContent={ "end"}
                            alignItems={"center"}
                            >
                                <Grid item
                                sx={{ mr: "50px" }}
                                >
                                    <WelcomeMessage/>
                                </Grid>
                            </Grid> :
                            isTabletLanding ?
                            <Grid container
                            direction="row-reverse"
                            width="100%"
                            height="100%"
                            display={ "flex"}
                            alignItems={"center"}
                            justifyContent={ "center"}
                            >
                                <Grid item>
                                    <WelcomeMessage/>
                                </Grid>
                            </Grid> :
                            <Grid container
                            direction="row-reverse"
                            width="100%"
                            display={ "flex"}
                            justifyContent={ "center"}
                            >
                                <Grid item>
                                    <WelcomeMessage/>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>

            {/* mission */}
            <Grid item xs={12} height="auto"
            minHeight="100vh"
            ref={missionRef}
            id="missionStatement"
            >
                <Grid container
                style={{
                background: mainColors.default,
                }}
                direction="row"
                >
                    {   
                        isLaptopLanding ?
                        <Grid item xs={4}
                        style={{backgroundImage: `url(${desktopLandingServices})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: "inherit",
                        minHeight:"100vh"
                        }}
                        display="flex"
                        justifyContent="flex-end"
                        >
                            <Grid container
                            style={{
                                width: "5vw",
                                height: "100%"}}
                            sx={{bgcolor: "rgba(255, 215, 111, 0.6)"}}
                            >
                            </Grid>
                        </Grid> :
                        <></>}

                    <Grid item xs={ isLaptopLanding ? 8 : 12}
                    display="flex"
                    alignItems="center"
                    sx={{px: resDesignLanding(100, 100, 60), py: resDesignLanding(150, 130, 100)}}
                    style={{ minHeight: "100vh" }}
                    >
                        <OurServices/>
                    </Grid>
                </Grid>
            </Grid>


            {/* Service cards */}
            <Grid item xs={12} height="auto"
            minHeight="100vh"
            ref={servicesRef}
            >
                <Grid container
                style={{
                background: mainColors.primary,
                }}
                display="flex"
                alignItems="center"
                justifyContent="space-evenly"
                >
                    <Grid item xs={12}>
                        <ServiceTiles/>
                    </Grid>
                </Grid>
            </Grid>

            {/* Testimonials */}
            <Grid item xs={12} height="auto"
            minHeight="100vh"
            ref={testimonialsRef}
            display="flex"
            >
                <Grid container
                style={{
                background: mainColors.default,
                }}
                >
                    <Grid item xs={12}
                    display="flex"
                    alignItems="center"
                    sx={{my:"15vh", px:"10vw"}}
                    >
                        <Testimonials/>
                    </Grid>
                </Grid>
            </Grid>
            
            <Grid item xs={12} height="auto">
                <Grid container
                style={{
                background: mainColors.primary,
                }}
                direction="row"
                >
                    <Grid item xs={12}
                    sx={{my:"5vh", px:"10vw"}}
                    
                    >
                        <Footer/>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>

        {/* <ScrollTop/> */}

        </>
    )
}

export default Landing
