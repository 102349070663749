import { Box, Grid, Typography } from "@mui/material"

const MatchingTilesChip = (props) => {

    const color = props.color
    const num = props.num
    const text = props.text

    return (
        <Grid item
        height="100%"
        width="100%"
        >
            <Box
            style={{ backgroundColor: color,
            borderRadius: 10
            }}
            height="100%"
            sx={{px:"0.5vw"}}
            >
                <Grid container
                direction="row"
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                >
                        <Grid item xs={4}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        >
                            <Typography
                            fontSize="3vh"
                            align="center"
                            display="flex"
                            alignItems="center"
                            >
                                {num}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        >
                            <Typography
                            fontSize="1.5vh"
                            align="center"
                            >
                                {text}
                            </Typography>
                        </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}

export default MatchingTilesChip