import { Avatar, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import axios from '../../api/axios';
import useAccessTokenInfo from '../../hooks/useAccessTokenInfo';
import { GET_PICS_URL } from '../../pages/URLs';
import { mainColors } from '../Theme/Colors/palette';

const AvatarWithUsername = () => {

    const accessTokenInfo = useAccessTokenInfo()
    const avatar = accessTokenInfo?.dispName || accessTokenInfo?.user || accessTokenInfo?.email || "NA"

    useEffect(() => {
        axios.post(GET_PICS_URL, 
            JSON.stringify({ email: accessTokenInfo.email, picKey: "profilePic" }),
            {
                headers: { 'Content-Type': 'application/json',
                'x-functions-key': `${process.env.REACT_APP_GET_PICS_KEY}` },
                withCredentials: true
            }
        ).then(res => {
            setAvatarImage(res.data.src)
        })
    }, [avatar])

    const [avatarImage, setAvatarImage] = useState("");

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}
            display="flex"
            justifyContent="center"
            sx={{mt:"2vh"}}
            >
                <Grid container direction="column"
                display="flex"
                width="auto"
                >
                    <Grid item
                    >
                        <Avatar style={{height:"20vh", width:"20vh", background: mainColors.accent}}
                        src={avatarImage || ""}
                        >
                            { avatarImage ? <></> : <Typography variant="h4">
                                {(avatar[0]+avatar[1]).toUpperCase()}
                            </Typography>}
                        </Avatar>
                    </Grid>
                    {/* <Grid item
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    >
                        <Typography variant="h4"
                        sx={{mt:"2vh"}}
                        >
                            Avatar
                        </Typography>
                    </Grid> */}
                </Grid>
            </Grid>

            <Grid item xs={12}
            display="flex"
            justifyContent="center"
            >
                <Typography
                variant="h6"
                >
                    {avatar}
                </Typography>
            </Grid>

        </Grid>
    )
}

export default AvatarWithUsername