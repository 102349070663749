import { useRef, useState, useEffect, useContext } from "react";
import axios from '../api/axios';
import { useNavigate } from "react-router-dom";
import { landingPage, loginPage, REGISTER_URL } from "./URLs";
import { Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import { EXISTING_EMAIL_URL } from "./URLs";
import desktopRegister from "../img/desktopRegister-min.jpg"
import { mainColors } from "../components/Theme/Colors/palette";
import WestIcon from '@mui/icons-material/West';
import CloseIcon from '@mui/icons-material/Close';
import ReCAPTCHA from "react-google-recaptcha"
import ResponsiveContext from "../ResponsiveDesign/ResponsiveProvider";
import KMButton from "../components/IndividualComponents/KMButton";

// const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const pwdMessage = (
    <>
        Password must be between 8 to 24 characters and contain
        <br/>
        Capital letter
        <br/>
        Lowercase letter
        <br/>
        Number
        <br/>
        One of these special characters: !@#$%
    </>
)

const Register = () => {

    const { isTablet } = useContext(ResponsiveContext)
    
    const navigate = useNavigate()
    
    const captchaRef = useRef(null)
    const emailRef = useRef();
    
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [focused, setFocused] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [focusedPwd, setFocusedPwd] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [focusedMatch, setFocusedMatch] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [emailErrMsg, setEmailErrMsg] = useState('');
    const [pwdErrMsg, setPwdErrMsg] = useState('');
    const [matchPwdErrMsg, setMatchPwdErrMsg] = useState('');

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const checkExistingEmail = async () => {
        try {
            await axios.post(EXISTING_EMAIL_URL,
                JSON.stringify({ email }),
                {
                    headers: { 'Content-Type': 'application/json',
                    'x-functions-key': `${process.env.REACT_APP_CHECK_EXISTING_EMAIL_KEY}` },
                    withCredentials: true
                })
        } catch (err) {
            if (!err?.response) {
                setErrMsg("No Server Response")
            } else if (err.response?.status === 409) {
                setValidEmail(false)
                setEmailErrMsg('Account with this email already exists');
            }
        }
    }

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
        setEmailErrMsg("Please enter a valid email")

        if (EMAIL_REGEX.test(email)) {
            checkExistingEmail()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setPwdErrMsg(pwdMessage)
        setValidMatch(pwd === matchPwd);
        setMatchPwdErrMsg("Both passwords must match")
    }, [pwd, matchPwd])

    const handleSubmit = async (e) => {
        setLoading(true)

        //captcha
        e.preventDefault()
        const token = captchaRef.current.getValue()

        if (!token) {
            setErrMsg("Please complete CAPTCHA");
            setLoading(false)
            return
        }

        // if button enabled with JS hack
        const v1 = EMAIL_REGEX.test(email);
        const v2 = PWD_REGEX.test(pwd);
        if (!v1) {
            setErrMsg("Must be a valid email");
            setValidEmail(false)
            setLoading(false)
            captchaRef.current.reset()
            return;
        }
        if (!v2) {
            setErrMsg("Must be a valid password")
            setValidPwd(false)
            setLoading(false)
            captchaRef.current.reset()
            return
        }

        if (!validMatch) {
            setErrMsg("Passwords must match")
            setValidMatch(false)
            setLoading(false)
            captchaRef.current.reset()
            return
        }

        try {
            await axios.post(REGISTER_URL,
                JSON.stringify({ email, pwd, "captchaToken": token }),
                {
                    headers: { 'Content-Type': 'application/json',
                    'x-functions-key': `${process.env.REACT_APP_CREATE_NEW_USER_KEY}` },
                    withCredentials: true
                });

            setTimeout(() => {
                captchaRef.current.reset()
                setSuccess(true);
                setLoading(false)
                //clear state and controlled inputs
                setEmail('');
                setPwd('');
                setMatchPwd('');
            }, 1500)
        } catch (err) {
            if (!err?.response) {
                setTimeout(() => {
                    setErrMsg('No Server Response');
                    setLoading(false)
                    captchaRef.current.reset()
                }, 1500)
            } else if (err.response?.status === 409) {
                setTimeout(() => {
                    setValidEmail(false)
                    setEmailErrMsg('Account with this email already exists');
                    setErrMsg('Account with this email already exists');
                    setLoading(false)
                    captchaRef.current.reset()
                }, 1500)
            } else if (err.response?.status === 400) {
                setTimeout(() => {
                    setErrMsg(err.response.data)
                    setLoading(false)
                    captchaRef.current.reset()
                }, 1500)
            } else if (err.response?.status === 500) {
                setTimeout(() => {
                    setErrMsg('Unexpected error, please try again later');
                    setLoading(false)
                    captchaRef.current.reset()
                }, 1500)
            } else {
                setTimeout(() => {
                    setErrMsg('Registration Failed');
                    setLoading(false)
                    captchaRef.current.reset()
                }, 1500)
            }
        }
    }

    return (
        <>
            <script src="https://www.google.com/recaptcha/api.js" async defer></script>
        <Grid container
        height="100vh"
        width="100vw"
        style={{backgroundImage: `url(${desktopRegister})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        minHeight: "100%",
        minWidth: "100%"}}
        >
            <Grid container
            height="100vh"
            width="100vw"
            style={{background: `rgba(0,0,0,0.92)`}}
            display="flex"
            justifyContent="center"
            >
                <Grid item xs={12}
                display="flex"
                height="3vh"
                alignItems="center"
                justifyContent="space-between"
                zIndex={ isTablet ? 0 : 1 }
                >
                    <Button
                    variant="text"
                    startIcon={<WestIcon style={{ fill: isTablet ? mainColors.background : "#000" }}/>}
                    sx={{ml:"1vw", mt:"2vh", px:2}}
                    disableRipple
                    onClick={() => navigate(landingPage)}
                    >
                        <Typography
                        color={isTablet ? mainColors.background : "#000"}
                        >
                            Back
                        </Typography>
                    </Button>

                    <IconButton
                    sx={{ml:"1vw", mt:"2vh"}}
                    onClick={() => navigate(landingPage)}
                    >
                        <CloseIcon
                        style={{fill: isTablet ? mainColors.background : "#000"}}
                        />
                    </IconButton>
                </Grid>

                <Grid item
                width={ isTablet ? "550px" : "100%" }
                height={ isTablet ? "auto" : "100%" }
                style={{background: mainColors.background,
                transform: "translate(-50%, -50%)"}}
                top="50%"
                left="50%"
                position="fixed"
                sx={{px:"4vw",}}
                display="flex"
                alignItems="center"
                justifyContent="center"
                >
                    { success ? ( 
                        <Grid container
                        display="flex"
                        justifyContent="center"
                        sx={{my:"5vh"}}
                        >
                            <Grid item xs={12}
                            display="flex"
                            justifyContent="center">
                                <Typography variant="h3"
                                sx={{mb:"3vh"}}
                                >
                                    Success!
                                </Typography>
                            </Grid>
                            <Grid item xs={12}
                            display="flex"
                            justifyContent="center">
                                <KMButton endArrow
                                variant={"impTitle"}
                                text={"Begin"}
                                onClick={() => navigate(loginPage)}/>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container
                        >
                            <Grid item xs={12}>

                                <Typography sx={{mt:"5vh"}}
                                variant="h3"
                                >
                                    Register
                                </Typography>
                                <Typography sx={{mt:"2vh"}}
                                variant="h5"
                                >
                                    Email
                                </Typography>
                                <TextField
                                ref={emailRef}
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                margin="dense"
                                fullWidth
                                size="small"
                                error={!validEmail && focused}
                                helperText={!validEmail && focused ? emailErrMsg : ""}
                                onFocus={() => {
                                    setFocused(true)
                                    setErrMsg("")
                                }}
                                onBlur={() => {
                                    setFocused(false)
                                }}
                                />

                                <Typography variant="h5"
                                sx={{mt:"1vh"}}
                                >
                                    Password
                                </Typography>
                                <TextField
                                value={pwd}
                                onChange={(e) => {
                                    setPwd(e.target.value)
                                }}
                                margin="dense"
                                fullWidth
                                size="small"
                                error={!validPwd && focusedPwd}
                                helperText={!validPwd && focusedPwd? pwdErrMsg : ""}
                                onFocus={() => {
                                    setFocusedPwd(true)
                                    setErrMsg("")
                                }}
                                onBlur={() => {
                                    setFocusedPwd(false)
                                }}
                                type="password"
                                />

                                <Typography variant="h5"
                                sx={{mt:"1vh"}}
                                >
                                    Confirm Password    
                                </Typography>
                                <TextField
                                value={matchPwd}
                                onChange={(e) => {
                                    setMatchPwd(e.target.value)
                                }}
                                margin="dense"
                                fullWidth
                                size="small"
                                error={!validMatch && focusedMatch}
                                helperText={!validMatch && focusedMatch? matchPwdErrMsg : ""}
                                onFocus={() => {
                                    setFocusedMatch(true)
                                    setErrMsg("")
                                }}
                                onBlur={() => {
                                    setFocusedMatch(false)
                                }}
                                type="password"
                                />
                            </Grid>
                            <Grid item xs={12}
                            sx={{mt:"3vh"}}
                            display="flex"
                            justifyContent="center"
                            >
                                <script src="https://www.google.com/recaptcha/api.js" async defer>
                                </script>
                                <ReCAPTCHA 
                                sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                                ref={captchaRef}
                                size="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sx={{mt:"1vh"}}>
                                <Typography variant="h6" style={{ color: "red" }}>
                                    {errMsg}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}
                            display="flex"
                            justifyContent="flex-end"
                            sx={{mt:"2vh", mb:"5vh"}}
                            >
                                <KMButton endArrow
                                variant={"impTitle"}
                                text={"Sign Up"}
                                onClick={handleSubmit}
                                loading={loading}/>
                            </Grid>
                        </Grid>
                        )}
                </Grid>
            </Grid>
        </Grid>
        </>
    )
}

export default Register
