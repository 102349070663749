import { Avatar, Grid, Typography } from "@mui/material"
import CottageIcon from '@mui/icons-material/Cottage';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { mainColors } from "../Theme/Colors/palette";
import DispPills from "../IndividualComponents/DispPills";
import { useNavigate } from "react-router-dom";
import { GET_PICS_URL, matchProfilePage } from "../../pages/URLs";
import KMButton from "../IndividualComponents/KMButton";
import axios from "../../api/axios";
import { useEffect, useState } from "react";

const SummarizedMatch = (props) => {

    const navigate = useNavigate()
    const matchingData = props.matchingData

    const [avatarImage, setAvatarImage] = useState("");

    useEffect(() => {
        axios.post(GET_PICS_URL, 
            JSON.stringify({ email: matchingData.user, picKey: "profilePic" }),
            {
                headers: { 'Content-Type': 'application/json',
                'x-functions-key': `${process.env.REACT_APP_GET_PICS_KEY}` },
                withCredentials: true
            }
        ).then(res => {
            if (res.data != "None" && res.data?.src) {
                setAvatarImage(res.data.src)
            }
        })
    }, [])

    return (
        <Grid container
        spacing={2.5}
        sx={{px:"2vw", py:"5vh"}}
        width="100%"
        >
            <Grid item xs={12} height="auto"
            >
                <Grid container direction="row"
                display="flex"
                alignItems="center"
                >
                    <Grid item width="auto"
                    display="flex"
                    justifyContent="left"
                    alignItems="center"
                    sx={{mr:"2vw"}}
                    >
                        <Avatar sx={{height: "18vh", width: "18vh"}}
                        src={avatarImage}>
                        </Avatar>
                    </Grid>
                    <Grid item xs={6}
                    >
                        <Typography variant="h3" sx={{mb:"1vh"}}>
                            {matchingData?.matchName || ""}
                        </Typography>
                        <Typography variant="h4" sx={{mb:"1vh"}}>
                            {matchingData?.oneLiner || ""}
                        </Typography>
                        <Typography variant="h5">
                            Height: {matchingData?.height || ""}m
                        </Typography>
                        <Typography variant="h5">
                            Age: {matchingData?.age || ""}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" sx={{mb:"1.4vh", mt:"0.7vh"}}>
                    My Preferences are to...
                </Typography>
                <Typography variant="h6"
                display="flex"
                alignItems="center"
                >
                    <CottageIcon sx={{mr:"1.4vw", fill: mainColors.purpleAccent}}/> {matchingData?.livingSituation || ""}
                </Typography>
                <Typography variant="h6"
                display="flex"
                alignItems="center"
                >
                    <FamilyRestroomIcon sx={{mr:"1.4vw", fill: mainColors.purpleAccent}}/> Have {matchingData?.children || ""} children
                </Typography>
                <Typography variant="h6"
                display="flex"
                alignItems="center"
                >
                    <TravelExploreIcon sx={{mr:"1.4vw", fill: mainColors.purpleAccent}}/> {matchingData?.relocation || ""}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" sx={{mb:"2vh"}}>
                    and I am looking for someone who is...
                </Typography>
                <DispPills array={matchingData?.pillPrefs || ["None"]} color={mainColors.green}/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" sx={{mt:"1vh"}}>
                    About {matchingData?.matchName || ""}
                </Typography>
                <Typography variant="h6">
                    {matchingData?.aboutMe || ""}
                </Typography>
            </Grid>
            <Grid item xs={12}
            display="flex"
            justifyContent="right"
            >
                <KMButton endArrow
                variant={"impTitle"}
                text={"View full profile"}
                onClick={() => navigate(matchProfilePage)}/>
            </Grid>
        </Grid>
    )
}

export default SummarizedMatch