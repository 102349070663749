import { motion } from "framer-motion"
import { useMemo } from "react"
import { mainColors } from "../Theme/Colors/palette"

const Sparklez = ({ endOfStartAnime, scaleDownDelay, enter, scaleDownDuration }) => {

    const numOfSparklez = 80

    const colors = [ mainColors.background, mainColors.primary, "#fff"]
    const generatorSparklez = (n) => {
        const numArr = [...Array(n).keys()]
        const pathsArr = numArr.map(() => {
            return {
                cx: Math.random() * 400,
                cy: Math.random() * 92.8,
                radius: Math.random() * 0.8,
                color: Math.floor(Math.random() * 3),
                delay: Math.random() * 7 + 1,
                duration: Math.random()*2 + 2
            }
        })
        return pathsArr
    }

    const dots = useMemo(() => generatorSparklez(numOfSparklez), [numOfSparklez])

    return (
        <>
        <motion.svg
        width={"100vw"}
        height={"50vh"}
        viewBox="0 0 400 92.8"
        style={{ maxWidth: "100%" }}
        initial={{
            scale: 1
        }}
        animate={{
            scale: enter ? 0 : 1
        }}
        transition={{
            delay: scaleDownDelay,
            duration: scaleDownDuration/1000
        }}
        >

        {
                    dots.map((props) => {
                        return (
                            <motion.circle
                            cx={props.cx}
                            cy={props.cy}
                            r={props.radius}
                            fill={colors[props.color]}
                            fillOpacity={0.5}
                            initial={{
                                opacity: 0
                            }}
                            animate={{
                                opacity: 1
                            }}
                            transition={{
                                delay: endOfStartAnime + props.delay,
                                duration: 2,
                                repeatType: "reverse",
                                repeat: Infinity
                            }}
                            />
                        )
                    })
                }
        </motion.svg>

        </>
    )
}

export default Sparklez