import CPTextComp from "./CompleteProfileIndividualComps/CPTextComp"
import CPMultilineTextComp from "./CompleteProfileIndividualComps/CPMultilineTextComp"
import CPRadioComp from "./CompleteProfileIndividualComps/CPRadioComp"
import CPDropdownComp from "./CompleteProfileIndividualComps/CPDropdownComp"
import CPSliderComp from "./CompleteProfileIndividualComps/CPSliderComp"
import CPRangeSliderComp from "./CompleteProfileIndividualComps/CPRangeSliderComp"
import CPPillComp from "./CompleteProfileIndividualComps/CPPillComp"
import CPDateComp from "./CompleteProfileIndividualComps/CPDateComp"
import CPEmptyComp from "./CompleteProfileIndividualComps/CPEmptyComp"
import CPAutocompleteCountry from "./CompleteProfileIndividualComps/CPAutocompleteCountry"
import CPAutocompleteCity from "./CompleteProfileIndividualComps/CPAutocompleteCity"
import CPAutocompleteEthnicity from "./CompleteProfileIndividualComps/CPAutocompleteEthnicity"
import CPAutocompleteLanguage from "./CompleteProfileIndividualComps/CPAutocompleteLanguage"
import CPAutocompleteSubject from "./CompleteProfileIndividualComps/CPAutocompleteSubject"
import CPAutocompleteIndustry from "./CompleteProfileIndividualComps/CPAutocompleteIndustry"

const availableComp ={
    "text": path => {return ( <CPTextComp path={path}/> )},
    "multiline": path => {return ( <CPMultilineTextComp path={path}/> )},
    "radio": path => {return ( <CPRadioComp path={path}/> )},
    "dropdown": path => {return ( <CPDropdownComp path={path}/> )},
    "slider": path => {return ( <CPSliderComp path={path}/> )},
    "rangeSlider": path => {return ( <CPRangeSliderComp path={path}/> )},
    "pill": path => {return ( <CPPillComp path={path}/> )},
    "date": path => {return ( <CPDateComp path={path}/> )},
    "empty": () => {return ( <CPEmptyComp/> )},
    "autocompleteCountry": path => {return ( <CPAutocompleteCountry path={path}/> )},
    "autocompleteCity": path => {return ( <CPAutocompleteCity path={path}/> )},
    "autocompleteEthnicity": path => {return ( <CPAutocompleteEthnicity path={path}/> )},
    "autocompleteLanguage": path => {return ( <CPAutocompleteLanguage path={path}/> )},
    "autocompleteSubject": path => {return ( <CPAutocompleteSubject path={path}/> )},
    "autocompleteIndustry": path => {return ( <CPAutocompleteIndustry path={path}/> )},
}

export default availableComp