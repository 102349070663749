import { Autocomplete, TextField } from "@mui/material"
import axios from "axios"
import { useContext, useEffect, useState } from "react"
import StructureContext from "../../../StructureDecoder/context/StructureProvider";

const CPAutocompleteCity = (props) => {
    const [cities, setCities] = useState([]);

    const { page, setPage, required, setRequired } = useContext(StructureContext)
    const path = props.path
    const thisPage = page(path[0])
    const dependsOnField = thisPage[path[1]][path[2]].dependsOn.field

    useEffect(() => {
        async function fetchData() { await axios.post("https://countriesnow.space/api/v0.1/countries/cities", {
            country: thisPage[path[1]][dependsOnField].value.toLowerCase()
        })
        .then(res => {
            const data = res.data.data
            const citiesList = data
            setCities(citiesList)
        })}
        fetchData()
    },[thisPage[path[1]][dependsOnField].value])

    const handleFocus = () => {
        if (required.includes(path[2])) {
            const index = required.indexOf(path[2]);
            required.splice(index, 1);
            setRequired(required)
        }
    }

    return (
        <Autocomplete
        fullWidth
        options={cities}
        value={thisPage[path[1]][path[2]].value}
        onChange={(e, newval) => {
            thisPage[path[1]][path[2]].value = newval
            setPage(path[0], thisPage)
        }}
        onFocus={handleFocus}
        renderInput={(params) => <TextField
            {...params}
            sx={{mt:2, px:1}}
            margin="dense"
            size="small"
            value={thisPage[path[1]][path[2]].value}
            />
        }
        />
    )
}

export default CPAutocompleteCity