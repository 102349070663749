import TitleTextPageLayout from "../components/CombinedComponents/TitleTextPageLayout";

const introText = {
    title: "Community Guidelines: Welcome to Krishna Marriage!",
    introduction: "We want Krishna Marriage to be a fun, safe and inclusive space for all devotees in ISKCON to create meaningful connections with others. We have created a set of community guidelines to set the expectation of all those who use our platform, on and off the application. Please read carefully, not following these guidelines can have real and meaningful consequences."
}

const body = [
    {
        title: "Consider boundaries",
        text: "Behind every profile is an individual and a person. Each person has a different comfort level and we should be respectful of each other's boundaries. Some information is meant for our Krishna Marriage community and other information is not."
    },
    {
        title: "Share cautiously and thoughtfully",
        text: "Please be careful when sharing information with other users. Do not share personal information like telephone numbers, address, bank details or any other information that might be personally identifiable. When you connect with someone after being matched, be thoughtful about what information you ask from them, and what information you share."
    },
    {
        title: "Make personal connections, not business",
        text: "Krishna Marriage is meant to make personal connections with each other. Here, everyone is looking for a life partner and not a business partner - if we all stay on the same wavelength then we will be able to make meaningful connections."
    },
    {
        title: "Be your authentic self",
        text: "In spirituality, honesty allows us to connect deeply with others. Other people want to see the real you, and get to know that person. Don't make fake accounts or impersonate others."
    },
    {
        title: "Communicate respectfully",
        text: `Be respectful and open in your first communication. We understand that talking to new people can be difficult, so try to keep it neutral to make the other person feel comfortable. If you find that a conversation is making you feel uncomfortable - pause and reflect, before you react. Anything to intentionally cause harm to others will not be tolerated.
        
        Krishna Marriage is not a place for hate. We will not tolerate racism, hatred or violence based on who someone is, how they identify or what they look like. If you find someone who doesn't meet your criteria, keep searching and move on. Don't report them unless you think they have breached our policies.`
    },
    {
        title: "Start with kindness, not harm",
        text: "Any acts that intend to cause harm to another user will be taken seriously. If you have been hurt by someone on Krishna Marriage, please take care of yourself first and take the time you need to heal. This might be spending time with loved ones, listening to your feel-better playlist or busting it out at the gym. If you feel that your hurt needs to be reported to us, please reach out. We are here to help."
    },
    {
        title: "Adults only",
        text: "You must be over the age of 18 to use Krishna Marriage. Please keep your profile photos appropriate for the platform and do not upload any photos with minors (which also means your younger self - although we are sure you were super cute)."
    },
    {
        title: "Abide by the law",
        text: "No illegal content or activities are allowed on Krishna Marriage, ever. You cannot use the platform to buy or sell illegal substances or ask for assistance to help you break the law, in whatever country you might be in. "
    },
    {
        title: "Stick around to stay active",
        text: "If you don't use your Krishna Marriage account for more than one year, we will assume that you have moved on and may delete your account for inactivity. So if you want to be seen in the app, just log in from time to time."
    },
    {
        title: "Reporting",
        text: "As a member of the devotee community which is the basis of Krishna Marriage, we encourage you to speak up. If someone is causing you harm or making you feel uncomfortable then report it. Your report will remain confidential."
    },
    {
        title: "Impact",
        text: `We take our community guidelines seriously. We will do everything we can to ensure that our users follow them. We have a warning system in place, and multiple consecutive warnings will lead to a permanent ban.
        
        We reserve the right to investigate and/or terminate accounts without a refund of any purchases if we find you have misused the Service or behaved in a way Krishna Marriage deems inappropriate, unlawful, or in violation of our Community Guidelines or Terms of Use.`
    }
]

const footerText = {
    title: "Thank you for reading",
    conclusion: "We hope you are enjoying the experience of Krishna Marriage"
}

const Guidelines = () => {
    return (
        <TitleTextPageLayout titleTextArray={body} headerTextObj={introText} footerTextObj={footerText}/>
    )
}

export default Guidelines
