import { useNavigate } from "react-router-dom";
import useLogout from "../hooks/useLogout";
import { landingPage } from "./URLs";

const Matchmaker = () => {
    const navigate = useNavigate();
    const logout = useLogout();

    const signOut = async () => {
        await logout();
        navigate(landingPage);
    }
    
    return (
        <section>
            <h1>Matchmaker Page</h1>
            <br/>
            <p>This is the matchmaker page, nothing here for now</p>

            <div className="flexGrow">
                <button onClick={signOut}>Sign Out</button>
            </div>

        </section>
    )
}

export default Matchmaker