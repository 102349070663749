import { Grid } from "@mui/material"
import { motion } from "framer-motion"

const GlintWipe = ({ finalShine }) => {
    return (
        <motion.svg
        viewBox="0 0 187.8 92.8"
        width={"100vw"}
        height={"100vh"}
        style={{ maxHeight: "100%", maxWidth: "100%" }}
        >
            <motion.path
            d="M -0.5 -0.5 C 0 -1 0 -2 0 -10 C 0 -1 0 -1 0.5 -0.5 C 1 0 2 0 10 0 C 2 0 1 0 0.5 0.5 C 0 1 0 2 0 10 C 0 2 0 1 -0.5 0.5 C -1 0 -2 0 -10 0 C -2 0 -1 0 -0.5 -0.5"
            fill="#fff"
            pathLength={2}
            strokeLinecap="butt"
            strokeLinejoin="miter"
            initial={{
                opacity: 0.5,
                rotate: 10,
                scale: 0,
                x: 93.9,
                y: 46.4
            }}
            animate={{
                opacity: 1,
                rotate: 100,
                scale: 2,
                x: 93.9,
                y: 46.4
            }}
            transition={{
                duration: finalShine/1000,
                delay: 0.5,
                ease: "backInOut",
                repeatType: "mirror",
                repeat: 1
            }}
            />
        </motion.svg>

    )
}

export default GlintWipe