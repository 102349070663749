import { Autocomplete, TextField } from "@mui/material"
import axios from "axios"
import { useContext, useEffect, useState } from "react"
import StructureContext from "../../../StructureDecoder/context/StructureProvider";

const subjectsList = `Accounting and Finance
Aeronautical and Manufacturing Engineering
Agriculture and Forestry
Anatomy and Physiology
Anthropology
Archaeology
Architecture
Art and Design
Biological Sciences
Building
Business and Management Studies
Chemical Engineering
Chemistry
Civil Engineering
Classics and Ancient History
Communication and Media Studies
Complementary Medicine
Computer Science
Counselling
Creative Writing
Criminology
Dentistry
Drama Dance and Cinematics
Economics
Education
Electrical and Electronic Engineering
English
Fashion
Film Making
Food Science
Forensic Science
General Engineering
Geography and Environmental Sciences
Geology
Health And Social Care
History
History of Art Architecture and Design
Hospitality Leisure Recreation and Tourism
Information Technology
Land and Property Management
Law
Linguistics
Marketing
Materials Technology
Mathematics
Mechanical Engineering
Medical Technology
Medicine
Music
Nursing
Occupational Therapy
Pharmacology and Pharmacy
Philosophy
Physics and Astronomy
Physiotherapy
Politics
Psychology
Robotics
Social Policy
Social Work
Sociology
Sports Science
Veterinary Medicine
Youth Work
Other`

const actualList = subjectsList.split("\n");

const CPAutocompleteSubject = (props) => {
    const [countries, setCountries] = useState(actualList);

    const { page, setPage, required, setRequired } = useContext(StructureContext)
    const path = props.path
    const thisPage = page(path[0])

    const handleFocus = () => {
        if (required.includes(path[2])) {
            const index = required.indexOf(path[2]);
            required.splice(index, 1);
            setRequired(required)
        }
    }

    return (
        <Autocomplete
        fullWidth
        options={countries}
        value={thisPage[path[1]][path[2]].value}
        onChange={(e, newval) => {
            thisPage[path[1]][path[2]].value = newval
            setPage(path[0], thisPage)
        }}
        onFocus={handleFocus}
        renderInput={(params) => <TextField
            {...params}
            sx={{mt:2, px:1}}
            margin="dense"
            size="small"
            value={thisPage[path[1]][path[2]].value}
            />
        }
        />
    )
}

export default CPAutocompleteSubject