import { Autocomplete, TextField } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import StructureContext from "../../../StructureDecoder/context/StructureProvider";

const ethnicitiesList = [
    "Indian",
    "Pakistani",
    "Bangladeshi",
    "Chinese",
    "Any other Asian background",
    "Caribbean",
    "African",
    "Any other Black, Black British, or Caribbean background",
    "White and Black Caribbean",
    "White and Black African",
    "White and Asian",
    "Any other Mixed or multiple ethnic background",
    "English, Welsh, Scottish, Northern Irish or British",
    "Irish",
    "Gypsy or Irish Traveller",
    "Roma",
    "Any other White background",
    "Arab",
    "Any other ethnic group",
]

const CPAutocompleteEthnicity = (props) => {
    const [countries, setCountries] = useState(ethnicitiesList);

    const { page, setPage, required, setRequired } = useContext(StructureContext)
    const path = props.path
    const thisPage = page(path[0])

    const handleFocus = () => {
        if (required.includes(path[2])) {
            const index = required.indexOf(path[2]);
            required.splice(index, 1);
            setRequired(required)
        }
    }

    return (
        <Autocomplete
        fullWidth
        options={countries}
        value={thisPage[path[1]][path[2]].value}
        onChange={(e, newval) => {
            thisPage[path[1]][path[2]].value = newval
            setPage(path[0], thisPage)
        }}
        onFocus={handleFocus}
        renderInput={(params) => <TextField
            {...params}
            sx={{mt:2, px:1}}
            margin="dense"
            size="small"
            value={thisPage[path[1]][path[2]].value}
            />
        }
        />
    )
}

export default CPAutocompleteEthnicity