import { Grid, Paper, Typography } from "@mui/material"
import { mainColors } from "../Theme/Colors/palette"
import desktopServiceTile1 from "../../img/desktopServiceTile1-min.jpg"
import desktopServiceTile2 from "../../img/desktopServiceTile2-min.jpg"
import desktopServiceTile3 from "../../img/desktopServiceTile3-min.jpg"
import { useContext } from "react"
import ResponsiveContext from "../../ResponsiveDesign/ResponsiveProvider"
import { useMediaQuery } from "@mui/material";
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

const ServiceTiles = () => {

    const { isLaptopLanding } = useContext(ResponsiveContext)
    const singleBreakpoint = useMediaQuery('(min-width:900px)')

    return (
        <Grid container
        display="flex"
        justifyContent="center"
        spacing={ singleBreakpoint ? 5 : 0}
        sx={{px: isLaptopLanding ? "100px" : singleBreakpoint ? "80px" : "70px", my:"15vh"}}
        >

            {   
                singleBreakpoint ?
                (<>
                    <Grid item xs={isLaptopLanding ? 4 : 6 }>
                    <Paper elevation={0}
                    style={{background: mainColors.primary}}
                    >
                        <Grid container
                        style={{backgroundImage: `url(${desktopServiceTile1})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: "55vh",
                        width: "100%"
                        }}
                        />

                        <Typography variant="h3"
                        align="center"
                        sx={{mt:"3vh", mb:"1.5vh"}}
                        >
                            Saving you money
                        </Typography>
                        <Typography variant="h5"
                        display="flex"
                        align="justify">
                        A new subscription service saves you money by allowing you to use our service as long as you want to and it gives you control with predictable payments
                        </Typography>
                    </Paper>
                </Grid>

                <Grid item xs={isLaptopLanding ? 4 : 6 }>
                <Paper elevation={0}
                style={{background: mainColors.primary}}
                >

                    <Grid container
                    style={{backgroundImage: `url(${desktopServiceTile2})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "55vh",
                    width: "100%"
                    }}
                    />

                    <Typography variant="h3"
                    align="center"
                    sx={{mt:"3vh", mb:"1.5vh"}}
                    >
                        Algorithmic matching
                    </Typography>
                    <Typography variant="h5"
                    display="flex"
                    align="justify">
                    Our computer based matching algorithm assists our team to find the best matches for you.
                    Personalised. Fast. Successful.
                    </Typography>
                </Paper>
                </Grid>

                <Grid item xs={isLaptopLanding ? 4 : 6 }>
                <Paper elevation={0}
                style={{background: mainColors.primary}}
                >

                    <Grid container
                    style={{backgroundImage: `url(${desktopServiceTile3})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "55vh",
                    width: "100%"
                    }}
                    />

                    <Typography variant="h3"
                    align="center"
                    sx={{mt:"3vh", mb:"1.5vh"}}
                    >
                        Encrypted
                    </Typography>
                    <Typography variant="h5"
                    display="flex"
                    align="justify">
                        Security is at the heart of our platform. Your data is stored encrypted in our databases and can only be access by you and your matchmaker.
                    </Typography>
                </Paper>
                </Grid>
                </>
            ) : (
                <Swiper
                spaceBetween={50}
                modules={[Pagination]}
                pagination={{ clickable: true }}
                >
                    <SwiperSlide>
                        <Paper elevation={0}
                        style={{background: mainColors.primary, paddingBottom: "50px"}}
                        >
                            <Grid container
                            style={{backgroundImage: `url(${desktopServiceTile1})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            height: "55vh",
                            width: "100%"
                            }}
                            />

                            <Typography variant="h3"
                            align="center"
                            sx={{mt:"3vh", mb:"1.5vh"}}
                            >
                                Saving you money
                            </Typography>
                            <Typography variant="h5"
                            display="flex"
                            align="justify">
                            A new subscription service saves you money by allowing you to use our service as long as you want to and it gives you control with predictable payments
                            </Typography>
                        </Paper>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Paper elevation={0}
                        style={{background: mainColors.primary, paddingBottom: "50px"}}
                        >

                            <Grid container
                            style={{backgroundImage: `url(${desktopServiceTile2})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            height: "55vh",
                            width: "100%"
                            }}
                            />

                            <Typography variant="h3"
                            align="center"
                            sx={{mt:"3vh", mb:"1.5vh"}}
                            >
                                Algorithmic matching
                            </Typography>
                            <Typography variant="h5"
                            display="flex"
                            align="justify">
                            Our computer based matching algorithm assists our team to find the best matches for you.
                            Personalised. Fast. Successful.
                            </Typography>
                        </Paper>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Paper elevation={0}
                        style={{background: mainColors.primary, paddingBottom: "50px"}}
                        >

                            <Grid container
                            style={{backgroundImage: `url(${desktopServiceTile3})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            height: "55vh",
                            width: "100%"
                            }}
                            />

                            <Typography variant="h3"
                            align="center"
                            sx={{mt:"3vh", mb:"1.5vh"}}
                            >
                                Encrypted
                            </Typography>
                            <Typography variant="h5"
                            display="flex"
                            align="justify">
                                Security is at the heart of our platform. Your data is stored encrypted in our databases and can only be access by you and your matchmaker.
                            </Typography>
                        </Paper>
                    </SwiperSlide>
                </Swiper>
            )
        }
        </Grid>
    )
}

export default ServiceTiles