import { Grid } from "@mui/material";
import DecodedStructure from "../components/ProfileDecoder/DecodedStructureUpdate"
import { useNavigate } from "react-router-dom";
import { StructureProviderUpdate } from "../StructureDecoder/context/StructureProviderUpdate";
import { StructureProvider } from "../StructureDecoder/context/StructureProvider";
import MainBody from "../Layout/MainBody";
import RightContent from "../Layout/RightContent";

const pageOrder = [4, 3, 2, 1]


const UpdateProfile = () => {

    const navigate = useNavigate()

    return (
        <>
            <MainBody>
                <Grid item
                style={{height: "auto"}}
                sx={{px:"1.5vw", mb: "6vh"}}
                >
                    <StructureProvider>
                        <StructureProviderUpdate>
                            <DecodedStructure
                            pageOrder={pageOrder}
                            />
                        </StructureProviderUpdate>
                    </StructureProvider>

                </Grid>
            </MainBody>

            <RightContent>
                <Grid item xs={2.64}
                style={{ height:"auto"}}
                >
                    Hey
                </Grid>
            </RightContent>
        </>
    )
        
}
    
export default UpdateProfile