import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { VERIFY_USER_EMAIL_URL } from "./URLs"
import useAccessTokenInfo from "../hooks/useAccessTokenInfo"
import MainBody from "../Layout/MainBody"
import LoadingComp from "../components/IndividualComponents/LoadingComp"
import axios from "../api/axios"
import { Grid, Typography } from "@mui/material"
import useRefreshToken from "../hooks/useRefreshToken"

const VerifyEmail = () => {
    const { verificationToken } = useParams()
    const refresh = useRefreshToken();

    const email = useAccessTokenInfo("email")

    const [success, setSuccess] = useState(null)

    useEffect(() => {

        const verifyUserEmail = async () => {
            try {
                await axios.post(VERIFY_USER_EMAIL_URL, 
                    JSON.stringify({ email, verificationToken }),
                    {
                        headers: { 'Content-Type': 'application/json',
                        'x-functions-key': `${process.env.REACT_APP_VERIFY_USER_EMAIL}` },
                        withCredentials: true
                    }
                ).then(async (res) => {
                    if (res?.data === "Done") {
                        setSuccess(true)
                        await refresh()
                    } else {
                        setSuccess(false)
                    }
                })
            } catch {
                setSuccess(false)
            }
        }
        verifyUserEmail()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <MainBody>
        {
            success === null ? (
                <Grid container
                style={{
                    height: "100vh",
                    width: "100%",
                }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                >
                    <LoadingComp width="15vw" />
                </Grid>
            ) : success === false ? (
                <Grid container
                style={{
                    height: "100vh",
                    width: "100%",
                }}
                sx={{ px: "2vw" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                >
                    <Grid item>
                        <Typography variant="h2" align="center">
                            Verification failed
                        </Typography>
                        <Typography variant="h4" align="center">
                            Please contact us at admin@krishnamarriage.com for manual verification
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid container
                style={{
                    height: "100vh",
                    width: "100%",
                }}
                sx={{ px: "2vw" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                >
                    <Grid item>
                        <Typography variant="h2" align="center">
                            Verification successful
                        </Typography>
                        <Typography variant="h4" align="center">
                            Please go back to the home page
                        </Typography>
                    </Grid>
                </Grid>
            )
        }
        </MainBody>
    )
}

export default VerifyEmail