import { Grid } from "@mui/material"
import { useState } from "react";
import axios from "../../api/axios";
import useAccessTokenInfo from "../../hooks/useAccessTokenInfo";
import { PREF_PILLS_URL } from "../../pages/URLs";
import CheckedPills from "../IndividualComponents/CheckedPills";
import UncheckedPills from "../IndividualComponents/UncheckedPills";
import KMButton from "../IndividualComponents/KMButton";

const CheckBoxPills = (props) => {

    const user = useAccessTokenInfo("user")

    const arrayOfPrefs = props.arrayOfPrefs
    const enterfunc = props.enterfunc
    const [checkedPills, setCheckedPills] = useState([]);
    const uncheckedPills = arrayOfPrefs.filter(x => !checkedPills.includes(x));

    const addPill = (pill) => {
        setCheckedPills([...checkedPills, pill])
    }

    const removePill = (index) => {
        const copyOfArr = [...checkedPills]
        copyOfArr.splice(index, 1)
        setCheckedPills([...copyOfArr])
    }

    const submitPrefPills = async () => {
        const post = {"user": user, "PrefPills": checkedPills}
        try {
            await axios.post(PREF_PILLS_URL,
                JSON.stringify(post),
                {
                    headers: { 'Content-Type': 'application/json',
                    'x-functions-key': `${process.env.REACT_APP_CREATE_PREF_PILL_KEY}` },
                    withCredentials: true
                }
            );
            enterfunc()
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <Grid container direction="column"
        >
            <Grid item sx={{mb:"3vh"}}
            xs={12}
            >
                { 
                checkedPills.length > 0
                ? checkedPills.map((pill, index) => {
                        return <CheckedPills 
                                key={pill} 
                                text={pill} 
                                func={removePill}
                                index={index}
                                />
                    })
                : <></>
                }
            </Grid>
            <Grid item
            xs={12}
            >
                {uncheckedPills.map((pill, index) => {
                    return <UncheckedPills 
                            key={pill} 
                            text={pill} 
                            func={addPill} 
                            />
                })}
            </Grid>
            <Grid item xs={12}
            display="flex"
            justifyContent="end"
            sx={{mr:"1vw", mt:"8vh"}}
            >
                <KMButton endArrow
                variant={"impTitle"}
                text={"Find my match"}
                onClick={submitPrefPills}/>
            </Grid>
        
        </Grid>
    )
}

export default CheckBoxPills