import { HomeRepairServiceOutlined } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ActionCenter from "../components/CombinedComponents/ActionCenter";
import InitialActionCenter from "../components/CombinedComponents/InitialActionCenter";
import useAccessTokenInfo from "../hooks/useAccessTokenInfo";
import MainBody from "../Layout/MainBody";
import RightContent from "../Layout/RightContent";
import { completeProfilePage, matchingPage, settingsPage, subscriptionsPage } from "./URLs";
import FaceIcon from '@mui/icons-material/Face';
import SavingsIcon from '@mui/icons-material/Savings';
import FavoriteIcon from '@mui/icons-material/Favorite';
import usePrefsDone from "../hooks/usePrefsDone";
import useGetUserMatchHistory from "../hooks/useGetUserMatchHistory";
import UserFeedCard from "../components/CombinedComponents/UserFeedCard";
import MailIcon from '@mui/icons-material/Mail';

const getStyles = (color) => {
    return {
        fontSize: "7vh",
        fill: color
    }
}


const Home = () => {

    let rightContentCard
    const accessTokenInfo = useAccessTokenInfo()
    const isReadyForMatches = accessTokenInfo?.readyForMatches || false
    const isSubbed = accessTokenInfo?.isSubbed || false
    const quickPrefsDone = usePrefsDone(accessTokenInfo?.email)
    const isEmailVerified = accessTokenInfo?.isEmailVerified || false

    const matchHistory = useGetUserMatchHistory(accessTokenInfo?.email)

    const navigate = useNavigate()

    const verifyYourEmail = {
        icon: <MailIcon style={getStyles("black")}/>,
        text: "Please verify your email. If the email didn't come through, contact support",
        func: () => null
    }
    const completeYourProfile = {
        icon: <FaceIcon style={getStyles("purple")}/>,
        text: "Continue to completing your profile or click on this card!",
        func: () => navigate(completeProfilePage)
    }
    const subscribe = {
        icon: <SavingsIcon style={getStyles("green")}/>,
        text: "Please subscribe so we can keep delivering the best matching services to you!",
        func: () => navigate(subscriptionsPage)
    }
    const goToMatching = {
        icon: <FavoriteIcon style={getStyles("red")}/>,
        text: "Head to matching to select the qualities you look for and match!",
        func: () => navigate(matchingPage)
    }

    if (!isEmailVerified) {
        rightContentCard = (<InitialActionCenter icon={verifyYourEmail.icon}
        text={verifyYourEmail.text} func={verifyYourEmail.func}/>)
    } else if (!isReadyForMatches) {
        rightContentCard = (<InitialActionCenter icon={completeYourProfile.icon}
            text={completeYourProfile.text} func={completeYourProfile.func}/>)
    } else if (!isSubbed) {
        rightContentCard = (<InitialActionCenter icon={subscribe.icon}
            text={subscribe.text} func={subscribe.func}/>)
    } else if (!quickPrefsDone || quickPrefsDone.data === "N") {
        rightContentCard = (<InitialActionCenter icon={goToMatching.icon}
            text={goToMatching.text} func={goToMatching.func}/>)
    } else {
        rightContentCard = (<ActionCenter/>)
    }

    return (
        <>
            <MainBody>
                <Grid item xs={12}
                sx={{
                    px: "2vw",
                    py: "3vh"
                }}
                >
                    <Typography variant="h3">
                        Feed
                    </Typography>
                    { matchHistory?.data?.seen?.length > 0 && <Typography variant="h4" key={"seen"}>
                        Seen
                    </Typography> }
                    <Grid container width={"100%"}>
                    { matchHistory?.data?.seen && matchHistory.data.seen.map(profile => {
                        return (
                            <Grid item sx={{my:2}} xs={12} key={profile.user}>
                                <UserFeedCard name={profile.name} age={profile.age} height={profile.height} user={profile.user}/>
                            </Grid>
                        )
                    }) }
                    </Grid>
                    { matchHistory?.data?.matches?.length > 0 && <Typography variant="h4" key={"matched"}>
                        Matched
                    </Typography> }
                    <Grid container width={"100%"}>
                    { matchHistory?.data?.matches && matchHistory.data.matches.map(profile => {
                        return (
                            <Grid item sx={{my:2}} xs={12} key={profile.user}>
                                <UserFeedCard name={profile.name} age={profile.age} height={profile.height} user={profile.user} email={profile.email}/>
                            </Grid>
                        )
                    }) }
                    </Grid>
                </Grid>
            </MainBody>

            <RightContent>
                {rightContentCard}
            </RightContent>
        </>
    )
}

export default Home
